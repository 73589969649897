import CONSTANTS from "../constants/actionConstants";

const initialState = {
  fetchError: "",
  booking: [],
  listOfMaids: [],
  fetchSuccess: false,
  totalBookings: 0,
  pageNumber: 0,
  bookingType: "",
  totalPages: 0,
  bookingDetails: {},
  changeRequestDetails: {},
  bookingAccepted: true,
  maidListSuccess: false,
  maidAlreadyFetched: false,
  bookingDetailsSuccess: false,
  changeRequestDetailsSuccess: false,
  cleanerAssignSuccess: false,
  bookingCounts: {
    bookingStatusNew:0,
    bookingEventStatusAll:0,
    bookingEventStatusPlanned:0,
    bookingEventStatusPendingConfirmation:0,
    bookingChangeRequest:0,
    bookingCancellation:0,
    bookingOnHoldJobs:0
  },
  chatCounts: 0
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.BOOKING_INIT:
      return {
        ...state,
        fetchError: "",
        booking: [],
        fetchSuccess: false
      };

    case CONSTANTS.GET_BOOKING_SUCCESSS: {
      return {
        ...state,
        fetchError: "",
        booking: action.bookings,
        fetchSuccess: true,
        totalBookings: action.totalBookings,
        bookingType: action.bookingType,
        totalPages: action.totalPages,
        pageNumber: action.pageNumber
      };
    }
    case CONSTANTS.GET_BOOKING_FAIL:
      return {
        ...state,
        fetchError: action.error,
        booking: [],
        fetchSuccess: false,
        totalBookings: 0,
        bookingType: action.bookingType
      };

    case CONSTANTS.BOOKING_ACCEPT_SUCCESS:
      return {
        ...state,
        bookingAccepted: true,
      }
    case CONSTANTS.BOOKING_ACCEPT_FAIL:
      return {
        ...state,
        bookingAccepted: false,
      }
    case CONSTANTS.BOOKING_DETAILS_SUCCESS: {
      return {
        ...state,
        bookingDetails: action.bookingDetails,
        bookingDetailsSuccess: true
      };
    }
    case CONSTANTS.BOOKING_DETAILS_FAIL:
    case CONSTANTS.BOOKING_DETAILS_RESET:
      return {
        ...state,
        bookingDetails: {},
        changeRequestDetails:{},
        changeRequestDetailsSuccess: false,
        bookingDetailsSuccess: false
      };
    case CONSTANTS.BOOKING_CHANGE_REQUEST_SUCCESS: {
      return {
        ...state,
        changeRequestDetails: action.changeRequestDetails,
        changeRequestDetailsSuccess: true
      };
    }
    case CONSTANTS.BOOKING_CHANGE_REQUEST_FAIL:
      return {
        ...state,
        changeRequestDetails: {},
        changeRequestDetailsSuccess: false
      };
    case CONSTANTS.GET_MAID_SUCCESS:
      return {
        ...state,
        listOfMaids: action.listOfMaids,
        maidListSuccess: true,
        maidAlreadyFetched: true
      };
    case CONSTANTS.GET_MAID_FAIL:
      return {
        ...state,
        listOfMaids: [],
        maidListSuccess: false
      };
    case CONSTANTS.CLEANER_ASSIGN_SUCCESS:
      return {
        ...state,
        cleanerAssignSuccess: true
      };
    case CONSTANTS.CLEANER_ASSIGN_FAIL:
      return {
        ...state,
        cleanerAssignSuccess: false
      };
    case CONSTANTS.BOOKING_COUNTS:
      return {
        ...state,
        bookingCounts: action.bookingCounts
      };
   case CONSTANTS.CHAT_COUNTS:
        return {
          ...state,
          chatCounts: action.chatCounts
        };
    default:
      return state;
  }
};
export default bookingReducer;
