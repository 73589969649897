import CONSTANTS from "../constants/actionConstants";

const initialState = {
    rating: {},
    leadCount: {},
    totalBill: {},
    bookingCount: {},
    futureEventList: [],
    monthlyLeadCount: [],
    bookingGrossRevenue: [],
    ratingSuccess: false,
    leadTypeSucces: false,
    leadCountSuccess: false,
    totalBillSuccess: false,
    bookingCountSuccess: false,
    futureEventListSuccess: false,
    monthlyLeadCountSuccess: false,
    bookingGrossRevenueSuccess: false,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.FUTURE_EVENT_SUCCESS: {
            return {
                ...state,
                futureEventList: action.futureEventList,
                futureEventListSuccess: true
            };
        }
        case CONSTANTS.FUTURE_EVENT_FAIL:
            return {
                ...state,
                futureEventList: [],
                futureEventListSuccess: false
            };
        case CONSTANTS.BOOKING_COUNT_INITIATE: {
            return {
                ...state,
                bookingCountSuccess: false,
            }
        }
        case CONSTANTS.BOOKING_COUNT_SUCCESS: {
            return {
                ...state,
                bookingCount: action.bookingCount,
                bookingCountSuccess: true
            };
        }
        case CONSTANTS.BOOKING_COUNT_FAIL: {
            return {
                ...state,
                bookingCount: {},
                bookingCountSuccess: false
            };
        }
        case CONSTANTS.LEAD_COUNT_INITIATE: {
            return {
                ...state,
                leadCountSuccess: false
            };
        }
        case CONSTANTS.LEAD_COUNT_SUCCESS: {
            return {
                ...state,
                leadCount: action.leadCount,
                leadCountSuccess: true
            };
        }
        case CONSTANTS.LEAD_COUNT_FAIL:
            return {
                ...state,
                leadCount: {},
                leadCountSuccess: false
            };
        case CONSTANTS.MONTHLY_LEAD_COUNT_SUCCESS: {
            return {
                ...state,
                monthlyLeadCount: action.monthlyLeadCount,
                monthlyLeadCountSuccess: true
            };
        }
        case CONSTANTS.MONTHLY_LEAD_COUNT_FAIL:
            return {
                ...state,
                monthlyLeadCount: [],
                monthlyLeadCountSuccess: false
            };
        case CONSTANTS.TOTAL_BILL_SUCCESS: {
            return {
                ...state,
                totalBill: action.totalBill,
                totalBillSuccess: true
            };
        }
        case CONSTANTS.TOTAL_BILL_FAIL: {
            return {
                ...state,
                totalBill: {},
                totalBillSuccess: false
            };
        }
        case CONSTANTS.BOOKING_GROSS_REVENUE_INITIATE:
            return {
                ...state,
                bookingGrossRevenueSuccess: false,
            };
        case CONSTANTS.BOOKING_GROSS_REVENUE_SUCCESS: {
            return {
                ...state,
                bookingGrossRevenue: action.bookingGrossRevenue,
                bookingGrossRevenueSuccess: true
            };
        }
        case CONSTANTS.BOOKING_GROSS_REVENUE_FAIL:
            return {
                ...state,
                bookingGrossRevenue: {},
                bookingGrossRevenueSuccess: false
            };
        case CONSTANTS.RATINGS_SUCCESS: {
            return {
                ...state,
                ratingSuccess: true,
                rating: action.rating,
            }
        }
        case CONSTANTS.RATINGS_FAIL: {
            return {
                ...state,
                ratingSuccess: true,
            }
        }
        case CONSTANTS.LEAD_TYPE_SUCCESS: {
            let filteredArrayIndex = [];
            let filteredLeadTypeObj = [{
                parentServiceName: 'All Types',
                parentServiceId: 0,
            }];
            action.leadTypes.forEach(value => {
                if (value.isRequestable && value.parentServiceId) {
                    if (!filteredArrayIndex.includes(value.parentServiceId)) {
                        filteredArrayIndex.push(value.parentServiceId);
                        filteredLeadTypeObj.push({
                            parentServiceName: value.parentServiceName,
                            parentServiceId: value.parentServiceId,
                        });
                    }
                }

            }
            );
            filteredLeadTypeObj = filteredLeadTypeObj.sort((a, b) => a.parentServiceName.localeCompare(b.parentServiceName));
            return {
                ...state,
                leadTypeSucces: true,
                filteredLeadTypeObj,
            }
        }
        case CONSTANTS.LEAD_TYPE_FAIL: {
            return {
                ...state,
                leadTypeSucces: false,
            }
        }
        default:
            return state;
    }
};
export default dashboardReducer;
