import {
  getEnvBaseDomainURL,
  getEnvBaseIntervalTime,
  getEnvForVersion,
  getSendBirdAppId,
  getSendBirdParterAppId
} from "./common";
const CODE_VERSION = "18.3.5";
export const PAGE_TITLE_SUFFIX = "| ServiceMarket Partner Portal";
export const PUBLIC_USER_ID = 8;
export const CURRENT_CODE_VERSION = CODE_VERSION;
export const DEFAULT_TIMEZONE = "Asia/Dubai";
export const DOMAIN_URL = {
  LOCAL: getEnvBaseDomainURL(process.env.REACT_APP_ENV),
  CURRENT_URL: getEnvBaseDomainURL(process.env.REACT_APP_ENV)
};
export const SENDBIRD = {
  APP_ID: getSendBirdAppId(process.env.REACT_APP_ENV),
  PARTNER_APP_ID: getSendBirdParterAppId(process.env.REACT_APP_ENV)
};
export const AVAILABLE_BALANCE_THRESHOLD = 300;
export const CACHE_FETCH_INTERVAL_TIME = getEnvBaseIntervalTime(
  process.env.REACT_APP_ENV
);
export const CHECK_VERSION_INTERVAL_TIME = getEnvBaseIntervalTime(
  process.env.REACT_APP_ENV
);
export const DRY_CLEANING_PRODUCT_ID = "sku_0d98b78074c90b6509";

export const PLAYER_ID = "PLAYER_ID";
export const APP = {
  VERSION: `v${CODE_VERSION}${getEnvForVersion()}`,
  DEFAULT_TIMEZONE: "Asia/Dubai",
  CITIES: {
    ID_MAPPING: {
      DUBAI: 1,
      ABU_DHABI: 2,
      SHARJAH: 3
    }
  },
  TITLES: {
    LOGIN: `Login ${PAGE_TITLE_SUFFIX}`,
    OPEN_BOOKINGS: `New Bookings ${PAGE_TITLE_SUFFIX}`,
    ACCEPTED_BOOKINGS: `Accepted Bookings ${PAGE_TITLE_SUFFIX}`,
    TODAY_BOOKINGS: "Today Dashboard",
    ACCEPTED_AND_UNASSIGNED_BOOKINGS: `Accepted and Unassigned Bookings ${PAGE_TITLE_SUFFIX}`,
    ON_HOLD_BOOKINGS: `On-hold Bookings ${PAGE_TITLE_SUFFIX}`,
    FREEZED_BOOKINGS: `Frozen Bookings ${PAGE_TITLE_SUFFIX}`,
    CHANGE_REQUESTS: `Change Requests ${PAGE_TITLE_SUFFIX}`,
    CANCELLED_BOOKINGS: `Cancelled Bookings ${PAGE_TITLE_SUFFIX}`,
    REJECTED_BOOKINGS: `Rejected Bookings ${PAGE_TITLE_SUFFIX}`,
    ALL_BOOKINGS: `All Jobs ${PAGE_TITLE_SUFFIX}`,
    PLANNED_JOBS: `Planned Jobs ${PAGE_TITLE_SUFFIX}`,
    PENDING_CONFIRMATION: `Pending Delivery Confirmation ${PAGE_TITLE_SUFFIX}`,
    DELIVERED_AND_PAID: `Delivered and Paid ${PAGE_TITLE_SUFFIX}`,
    DELIVERED_AND_UNPAID: `Delivered and Unpaid ${PAGE_TITLE_SUFFIX}`,
    CANCELLED_EVENTS: `Cancelled ${PAGE_TITLE_SUFFIX}`,
    CANCELLED_AND_PAID: `Cancelled and Paid ${PAGE_TITLE_SUFFIX}`,
    CANCELLED_JOBS: `Cancelled Jobs ${PAGE_TITLE_SUFFIX}`,
    BOOKING_DETAILS_PAGE: `Booking Details ${PAGE_TITLE_SUFFIX}`,
    EVENT_DETAILS_PAGE: `Event Details ${PAGE_TITLE_SUFFIX}`,
    NEW_QUOTES: `New Request ${PAGE_TITLE_SUFFIX}`,
    ACCEPTED_QUOTES: `Accepted Leads ${PAGE_TITLE_SUFFIX}`,
    REJECTED_QUOTES: `Rejected Leads ${PAGE_TITLE_SUFFIX}`,
    ALL_QUOTES: `All Leads ${PAGE_TITLE_SUFFIX}`,
    QUOTE_DETAILS: `Quote Details ${PAGE_TITLE_SUFFIX}`,
    PROMOTION_LEAD: `Promotion Lead ${PAGE_TITLE_SUFFIX}`,
    MY_BILLING: `My Billing ${PAGE_TITLE_SUFFIX}`,
    ADD_MEMBER: `Add Member ${PAGE_TITLE_SUFFIX}`,
    UPDATE_MEMBER: `Update Member ${PAGE_TITLE_SUFFIX}`,
    TEAM_MEMBER_LIST: `Team Member List ${PAGE_TITLE_SUFFIX}`,
    VIEW_MEMBER_DETAIL: `Team Details ${PAGE_TITLE_SUFFIX}`,
    PAYMENT: `Pay ${PAGE_TITLE_SUFFIX}`,
    VIEW_CARD: `View Card ${PAGE_TITLE_SUFFIX}`,
    TRANSACTION_HISTORY: `Transaction History ${PAGE_TITLE_SUFFIX}`,
    PROFILE: `Profile ${PAGE_TITLE_SUFFIX}`,
    INVITE_USER_LIST: `Invites List ${PAGE_TITLE_SUFFIX}`
  },
  GENERIC_MAPPERS: {
    BOOKING_AND_EVENT_TYPES: {
      NEW_BOOKINGS: "NEW_BOOKINGS",
      BOOKING_ALLOCATION_STATUS_BANKED: "BOOKING_ALLOCATION_STATUS_BANKED",
      SOFT_BOOKINGS: "SOFT_BOOKINGS",
      ACCEPTED_BOOKINGS: "ACCEPTED_BOOKINGS",
      ACCEPTED_AND_UNASSIGNED_BOOKINGS: "ACCEPTED_AND_UNASSIGNED_BOOKINGS",
      ON_HOLD_BOOKINGS: "ON_HOLD_BOOKINGS",
      FREEZED_BOOKINGS: "FREEZED_BOOKINGS",
      CHANGE_REQUESTS: "CHANGE_REQUESTS",
      CANCELLED_BOOKINGS: "CANCELLED_BOOKINGS",
      ALL_BOOKINGS: "ALL_BOOKINGS",
      PLANNED_JOBS: "PLANNED_JOBS",
      PENDING_CONFIRMATION: "PENDING_CONFIRMATION",
      IN_PROGRESS: "IN_PROGRESS",
      PENDING_IN_PROGRESS: "PENDING_IN_PROGRESS",
      DELIVERED_AND_PAID: "DELIVERED_AND_PAID",
      DELIVERED_AND_UNPAID: "DELIVERED_AND_UNPAID",
      CANCELLED_EVENTS: "CANCELLED_EVENTS",
      CHANGE_REQUESTED_EVENT: "CHANGE_REQUESTED_EVENT",
      CANCELLED_AND_PAID: "CANCELLED_AND_PAID",
      CANCELLED_AND_UNPAID: "CANCELLED_AND_UNPAID",
      REJECTED_BOOKINGS: "REJECTED_BOOKINGS",
      BOOKING_EVENT_STATUS_QUOTE: "BOOKING_EVENT_STATUS_QUOTE",
      BOOKING_EVENT_QUOTE_STATUS_OFFERED: "BOOKING_EVENT_QUOTE_STATUS_OFFERED",
      BOOKING_EVENT_QUOTE_STATUS_NEGOTIATION:
        "BOOKING_EVENT_QUOTE_STATUS_NEGOTIATION"
    },
    QUOTES_REQUEST_TYPES: {
      OPEN: "OPEN",
      ACCEPTED: "ACCEPTED",
      REJECTED: "REJECTED",
      ALL: "ALL"
    },
    BOOKING_RECORD_TYPES: {
      BOOKING: "BOOKING",
      EVENT: "EVENT" // Changed from BOOKING_EVENT for cancellation reasons.
    },
    PAYMENT_METHODS: {
      CREDIT_CARD: "CREDIT_CARD",
      CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
      NOT_VERIFIED: "BOOKING_PAYMENT_METHOD_EMPTY"
    },
    BOOKING_AND_EVENT_DETAILS_PAGE_TAB: {
      DETAILS: "DETAILS_TAB",
      PARTNERS: "ALLOCATED_PARTNERS_TAB",
      CHANGE_REQUESTS: "CHANGE_REQUEST_TAB",
      HISTORY: "HISTORY_TAB",
      ZENDESK_TICKETS: "ZENDESK_TICKETS",
      SUPPORT_TICKETS: "SUPPORT_TICKETS",
      CALL_LOG: "CALL_LOG",
      CHAT: "CHAT",
      SM_CHAT: "SM_CHAT"
    },
    REQUESTED_BY: {
      CUSTOMER: "customer",
      SERVICE_PROVIDER: "service provider"
    },
    BOOKING_APARTMENT_TYPES: {
      STUDIO: "Studio"
    }
  },
  PLACEHOLDERS: {
    SEARCH_FIELD: "Type and press search",
    GRIDS_LOADING_DATA: "Fetching records..."
  },
  BOOKING_FREQUENCY: {
    ONCE: "ONCE"
  },
  BOOKING_REFERENCE_TYPE: {
    COPY_BOOKING: "COPY_BOOKING",
    BOOK_AGAIN: "BOOK_AGAIN"
  },
  BOOKING_PROPERTY_TYPES: {
    APARTMENT: "apartment",
    VILLA: "villa"
  },
  REQUESTED_BY_TYPE: {
    CUSTOMER: "Customer",
    SERVICE_PROVIDER: "Service provider",
    PARTNER: "Partner",
    Customer: "Customer",
    "Service Provider": "Service Provider" // For legacy v1 API
  },
  FIREBASE_CONFIG: getFirebaseConfig(process.env.REACT_APP_ENV),
  VAPID_KEY: getFirebaseVapidID(process.env.REACT_APP_ENV),
  FIREBASE_SW_FILE_NAME: getFirebaseSWFilename(process.env.REACT_APP_ENV),
  BOOKING_AND_EVENT_DETAILS_PAGE_TAB: {
    DETAILS: "DETAILS_TAB",
    ZENDESK_TICKETS: "ZD_TICKETS"
  }
};

export const APP_ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  PROFILE: "/profile",
  CHAT: "/chat",
  PARTNERS_CSR_CHAT: "/sm-chat",
  CHAT_WITH_URL: "/chat/:url",
  CALENDAR: "/calendar",
  ABSENT_DASHBOARD: "/absent-dashboard",
  ABSENT_LOGS: "/absent-logs",
  COMPLETE_REGISTRATION: "/complete-registration/:token/:step",
  /*COMPLETE_REGISTRATION_STEP1 : "/complete-registration/:token/step-2",
  COMPLETE_REGISTRATION_STEP2 : "/complete-registration/:token/step-3",*/
  SERVICE_PROVIDERS: {
    //LIST: "/service-providers",
    ALL: "/service-provider/:tab",
    /*TECHNICIANS: "/service-provider/technicians",
    USERS: "/service-provider/users",
    INVITES: "/service-provider/invites",*/
    VIEW: "/service-provider"
  },
  BOOKINGS: {
    LIST: {
      OPEN: "/bookings/open",
      ACCEPTED: "/bookings/accepted",
      ACCEPTED_AND_UNASSIGNED: "/bookings/accepted-unassigned",
      ON_HOLD: "/bookings/on-hold",
      DELIVERED_AND_PAID: "/bookings/delivered-paid",
      DELIVERED_AND_UNPAID: "/bookings/delivered-unpaid",
      REJECTED: "/bookings/rejected",
      FROZEN: "/bookings/frozen",
      CANCELLED: "/bookings/cancelled",
      ALL: "/bookings/all",
      TODAY_JOB_BOARD: "/bookings/list/today",
      BANKED: "/bookings/banked",
      FOLLOW_UP_QUOTE_REQUIRED: "/bookings/follow-up-quotes-required",
      FOLLOW_UP_QUOTE_SENT: "/bookings/follow-up-quotes-sent"
    },
    JOB_UPDATES: "/bookings/job-updates",
    DETAILS: "/bookings/:bookingType/details/:serviceCode/:bookingId"
  },
  EVENTS: {
    LIST: {
      PLANNED_JOBS: "/event/planned-jobs",
      PENDING_CONFIRMATION: "/event/pending-confirmation",
      IN_PROGRESS: "/event/in-progress",
      PENDING_IN_PROGRESS: "/event/pending-in-progress",
      CANCELLED_AND_PAID: "/event/all-cancelled-events"
    },
    DETAILS: "/event/:eventType/details/:serviceCode/:bookingEventId",
    DETAILS_OSP:
      "/osp-event/details/:serviceCode/:bookingEventId/worker/:workerId/provider/:ospId"
  },
  CHANGE_REQUESTS: {
    LIST: "/bookings/change-request"
  },
  TEAM_MEMBER: {
    REGISTER_MEMBER: "/member/register",
    LIST_ALL_MEMBER: "/memberList",
    MEMBER_DETAILS: "/memberList/:cleanerID",
    MEMBER_UPDATE: "/memberUpdate/:cleanerID"
  },
  PROMOTION: {
    LEADS_LIST: "/promotion/leads",
    LEAD_DETAILS: "/promotion/leadDetails/:leadID"
  },
  QUOTES: {
    NEW_REQUEST: "/quotes/new-requests",
    ACCEPTED_REQUEST: "/quotes/accepted-requests",
    REJECTED_REQUEST: "/quotes/rejected-requests",
    ALL_REQUEST: "/quotes/all-requests",
    REQUEST_DETAILS: "/quotes/details/:serviceName/:serviceID/:quotesID"
  },
  REPORTS: {
    MY_BILLING: "/reports/my-billing"
  },
  PAYMENT: {
    PAY: "/payment/pay",
    VIEW_CARD: "/payment/view-card",
    VIEW_CARD_DETAILS: "/payment/view-card/:status/:id",
    TRANSCATION_HISTORY: "/payment/transaction-history"
  },
  ZENDESK: {
    LIST: "/tickets/list",
    SUPPORT_TICKETS: "/tickets/support/list"
  },
  WIKI_PAGE: {
    PAGE: "/wiki/:file"
  },
  NOTIFICATION_CENTER: {
    LIST: "/notification-center/list/:status"
  },
  RATING: {
    LIST: "/rating/list"
  }
};
export const ERRORS = {
  DEFAULT_MESSAGE: "Something went wrong! Please try again.",
  AUTH: {
    EMPTY_USERNAME_PASSWORD: {
      CODE: "empty_username_password",
      MESSAGE: "Username or password cannot be empty."
    },
    INCORRECT_USERNAME_PASSWORD: {
      CODE: "invalid_grant",
      MESSAGE: "Invalid username or password. Please try again."
    }
  },
  REQUEST_FAILED_GENERAL: "Unable to process your request. Please try again.",
  CODES: {
    UNAUTHORIZED_ACCESS: 401,
    RESOURCE_CREATED: 201,
    BAD_REQUEST: 400
  },
  ALREADY_ACCEPTED_BOOKING:
    "looks like this request has already been accepted by someone else! There are always more bookings to accept.",
  EMPTY_GRID: "Sorry, there are no records to display.",
  EMPTY_CHANGE_REQUEST_LIST:
    "No change request is created on this :bookingRecordType.",
  UNEXPECTED_API_ERROR: "Error! Something went wrong, Please refresh page.",
  CONTACT_SUPPORT: "Error! Request terminated",
  INTERNAL_SERVER_ERROR: "Error! Server not responded",
  CREDIT_CARD: {
    CC_DECLINE:
      "Your attempt to add a credit card has been declined. Please try again.",
    CC_CANCEL:
      "Your attempt to add a credit card was unsuccessful. Please try again.",
    CC_NO_CARD:
      'You don\'t have any credit cards in the system. Please <a href="#/pages/payments/add">add a credit card</a>.'
  }
};

export const SORT_MAPPERS = {
  BOOKINGS: {
    service: "BOOKING_SERVICE_NAME",
    serviceDate: "BOOKING_SERVICE_DATE",
    serviceTime: "BOOKING_SERVICE_TIME",
    city: "BOOKING_SERVICE_LOCATION_NAME",
    area: "BOOKING_ADDRESS_APARTMENT",
    frequency: "BOOKING_FREQUENCY",
    priceDetail: "BOOKING_PRICE",
    paymentMethod: "BOOKING_PAYMENT_METHOD",
    uuid: "BOOKING_UUID",
    customerName: "BOOKING_CONTACT_INFORMATION_NAME",
    building: "BOOKING_ADDRESS_BUILDING",
    apartment: "BOOKING_ADDRESS_APARTMENT",
    creationDateTime: "BOOKING_CREATION_DATE_TIME"
  },
  EVENTS: {
    bookingEventId: "BOOKING_EVENT_ID",
    service: "BOOKING_EVENT_SERVICE_NAME",
    serviceDate: "BOOKING_EVENT_SERVICE_DATE,BOOKING_EVENT_SERVICE_TIME",
    serviceTime: "BOOKING_EVENT_SERVICE_TIME",
    city: "BOOKING_EVENT_SERVICE_LOCATION_NAME",
    area: "BOOKING_EVENT_ADDRESS_APARTMENT",
    address: "BOOKING_EVENT_ADDRESS_APARTMENT",
    frequency: "BOOKING_EVENT_FREQUENCY",
    priceDetail: "BOOKING_EVENT_PRICE",
    paymentMethod: "BOOKING_EVENT_PAYMENT_METHOD",
    uuid: "BOOKING_EVENT_UUID",
    customerName: "BOOKING_EVENT_CONTACT_INFORMATION_NAME",
    contactInformation: "BOOKING_EVENT_CONTACT_INFORMATION_NAME",
    building: "BOOKING_EVENT_ADDRESS_BUILDING",
    apartment: "BOOKING_EVENT_ADDRESS_APARTMENT",
    statusModifiedDate: "BOOKING_EVENT_STATUS_MODIFIED_DATE"
  },
  ZENDESK_TICKETS_GRID_CONST: {
    id: "TICKET_ID",
    creationDateTime: "CREATION_TIME",
    serviceName: "SERVICE_NAME",
    partnerName: "PARTNER_NAME",
    customerName: "CUSTOMER_NAME",
    complaint_reason: "COMPLAINT_REASON"
  },
  ZENDESK_SUPPORT_TICKETS_GRID_CONST: {
    id: "SDT_TICKET",
    creationDateTime: "SDT_CREATED_AT",
    serviceName: "SDT_SERVICE",
    customerName: "SDT_CUSTOMER",
    complaint_reason: "SDT_REASON"
  }
};

export const BOOKING_AND_EVENT_STATUSES = {
  CONST_STRING_MAPPING: {
    BOOKING_STATUS_NEW: { label: "New Booking", color: "primary" },
    BOOKING_STATUS_BANKED: { label: "New Booking", color: "primary" },

    BOOKING_STATUS_CHANGE_REQUESTED: {
      label: "Change Requested",
      color: "warning"
    },
    BOOKING_STATUS_CONFIRMED: { label: "Accepted Booking", color: "success" },
    BOOKING_STATUS_ACCEPTED_AND_UNASSIGNED: {
      label: "Accepted & Unassigned",
      color: "primary"
    },
    BOOKING_STATUS_CANCELLED: { label: "Cancelled Booking", color: "danger" },
    BOOKING_STATUS_ON_HOLD: { label: "On-hold Booking", color: "danger" },
    BOOKING_EVENT_STATUS_ON_HOLD: { label: "On-hold", color: "danger" },

    BOOKING_STATUS_FREEZED: { label: "Freezed Booking", color: "danger" },
    BOOKING_EVENT_STATUS_PLANNED: { label: "Planned Job", color: "primary" },
    BOOKING_EVENT_STATUS_PENDING_CONFIRMATION: {
      label: "Pending Confirmation",
      color: "primary"
    },
    BOOKING_EVENT_STATUS_IN_PROGRESS: {
      label: "Laundry Picked-up",
      color: "primary"
    },
    BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS: {
      label: "Pending In Progress",
      color: "primary"
    },
    BOOKING_EVENT_STATUS_DELIVERED_AND_PAID: {
      label: "Delivered & Paid",
      color: "primary"
    },
    BOOKING_EVENT_STATUS_DELIVERED_AND_UNPAID: {
      label: "Delivered & Unpaid",
      color: "warning"
    },
    BOOKING_EVENT_STATUS_CANCELLED: {
      label: "Cancelled Event",
      color: "danger"
    },
    BOOKING_EVENT_STATUS_CHANGE_REQUESTED: {
      label: "Change Requested",
      color: "warning"
    },
    BOOKING_EVENT_STATUS_CANCELLED_AND_PAID: {
      label: "Cancelled Late",
      color: "danger"
    },
    BOOKING_EVENT_STATUS_CANCELLED_AND_UNPAID: {
      label: "Cancelled Event",
      color: "danger"
    },
    // Only valid for partner
    BOOKING_CONFIRMATION_STATUS_PENDING: {
      label: "New Booking",
      color: "primary"
    },
    BOOKING_CONFIRMATION_STATUS_ACCEPTED: {
      label: "Accepted Booking",
      color: "success"
    },
    BOOKING_CONFIRMATION_STATUS_REJECTED: {
      label: "Rejected Booking",
      color: "danger"
    },
    BOOKING_CONFIRMATION_STATUS_CANCELLED: {
      label: "Cancelled Booking",
      color: "danger"
    },
    BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED: {
      label: "Late Accepted Booking",
      color: "success"
    },
    BOOKING_EVENT_STATUS_QUOTE: {
      label: "Follow up - Quotes Required",
      color: "primary"
    },
    BOOKING_EVENT_QUOTE_STATUS_OFFERED: {
      label: "Follow up - Quotes Sent",
      color: "success"
    },
    BOOKING_EVENT_QUOTE_STATUS_NEGOTIATION: {
      label: "Follow up - Quotes Negotiation",
      color: "warning"
    },
    BOOKING_EVENT_STATUS_CANCELLATION_REQUESTED: {
      label: "Pending Cancellation",
      color: "warning"
    }
  },
  CONST_MAPPING: {
    NEW_BOOKING: "BOOKING_STATUS_NEW",
    BANKED_BOOKING: "BOOKING_STATUS_BANKED",
    CHANGE_REQUEST: "BOOKING_STATUS_CHANGE_REQUESTED",
    ACCEPTED_BOOKING: "BOOKING_STATUS_CONFIRMED",
    CANCELLED_BOOKING: "BOOKING_STATUS_CANCELLED",
    ON_HOLD: "BOOKING_STATUS_ON_HOLD",
    PLANNED_EVENT: "BOOKING_EVENT_STATUS_PLANNED",
    PENDING_CONFIRMATION_EVENT: "BOOKING_EVENT_STATUS_PENDING_CONFIRMATION",
    DELIVERED_AND_PAID_EVENT: "BOOKING_EVENT_STATUS_DELIVERED_AND_PAID",
    DELIVERED_AND_UNPAID_EVENT: "BOOKING_EVENT_STATUS_DELIVERED_AND_UNPAID",
    IN_PROGRESS: "BOOKING_EVENT_STATUS_IN_PROGRESS",
    CANCELLED_EVENT: "BOOKING_EVENT_STATUS_CANCELLED",
    CHANGE_REQUEST_EVENT: "BOOKING_EVENT_STATUS_CHANGE_REQUESTED",
    CANCELLED_AND_PAID_EVENT: "BOOKING_EVENT_STATUS_CANCELLED_AND_PAID",
    BOOKING_EVENT_STATUS_QUOTE: "BOOKING_EVENT_STATUS_QUOTE",
    BOOKING_EVENT_QUOTE_STATUS_OFFERED: "BOOKING_EVENT_QUOTE_STATUS_OFFERED",
    BOOKING_EVENT_QUOTE_STATUS_EDITABLE: "BOOKING_EVENT_QUOTE_STATUS_EDITABLE",
    BOOKING_EVENT_QUOTE_STATUS_NEGOTIATION:
      "BOOKING_EVENT_QUOTE_STATUS_NEGOTIATION",
    BOOKING_EVENT_STATUS_ON_HOLD: "BOOKING_EVENT_STATUS_ON_HOLD",
    BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS:
      "BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS"
  },
  ID_CONST_MAPPING: {
    68: "BOOKING_STATUS_NEW",
    355: "BOOKING_STATUS_BANKED",
    69: "BOOKING_STATUS_CHANGE_REQUESTED",
    70: "BOOKING_STATUS_CONFIRMED",
    72: "BOOKING_STATUS_CANCELLED",
    80: "BOOKING_CONFIRMATION_STATUS_ACCEPTED",
    254: "BOOKING_STATUS_ON_HOLD",
    73: "BOOKING_EVENT_STATUS_PLANNED",
    74: "BOOKING_EVENT_STATUS_PENDING_CONFIRMATION",
    76: "BOOKING_EVENT_STATUS_DELIVERED_AND_PAID",
    77: "BOOKING_EVENT_STATUS_CANCELLED",
    78: "BOOKING_EVENT_STATUS_CHANGE_REQUESTED",
    255: "BOOKING_EVENT_STATUS_ON_HOLD",
    265: "BOOKING_EVENT_STATUS_CANCELLED_AND_PAID"
  },
  CONST_ID_MAPPING: {
    BOOKING_STATUS_NEW: 68,
    BOOKING_STATUS_BANKED: 355,
    BOOKING_STATUS_CHANGE_REQUESTED: 69,
    BOOKING_STATUS_CONFIRMED: 70,
    BOOKING_STATUS_CANCELLED: 72,
    BOOKING_CONFIRMATION_STATUS_ACCEPTED: 80,
    BOOKING_STATUS_UNVERIFIED_PAYMENT_METHOD: 91,
    BOOKING_STATUS_ON_HOLD: 254,
    BOOKING_EVENT_STATUS_PLANNED: 73,
    BOOKING_EVENT_STATUS_PENDING_CONFIRMATION: 74,
    BOOKING_EVENT_STATUS_DELIVERED_AND_PAID: 76,
    BOOKING_EVENT_STATUS_CANCELLED: 77,
    BOOKING_EVENT_STATUS_CHANGE_REQUESTED: 78,
    BOOKING_EVENT_STATUS_CANCELLED_AND_PAID: 265,
    BOOKING_EVENT_STATUS_CANCELLED_AND_UNPAID: 281,
    BOOKING_EVENT_STATUS_DELIVERED_AND_UNPAID: 75,
    BOOKING_EVENT_STATUS_ON_HOLD: 255
  }
};

export const COMPARISON_CONSTANTS = {
  AVAILABLE_BALANCE_THRESHOLD: 300
};

export const COMMON_PREFIX = {
  TELR_DESCRIPTION_PREFIX:
    "Partner Transaction (%payment_for%) - %partner_name% [%user_name%] | "
};

export const REJECTION_REASON = {
  QUOTE: {
    QUOTE_REJECTION_REASON_FOR_DOMESTIC_MOVING: 28,
    QUOTE_REJECTION_REASON_FOR_INT_MOVING: 29,
    QUOTE_REJECTION_REASON_FOR_STORAGE: 30,
    QUOTE_REJECTION_REASON_FOR_HOUSEHOLD: 31,
    QUOTE_REJECTION_REASON_FOR_CAR_SHIPPING: 33,
    QUOTE_REJECTION_REASON_FOR_CURTAINS: 60,
    QUOTE_REJECTION_REASON_FOR_CATERING: 61,
    QUOTE_REJECTION_REASON_FOR_PHOTOGRAPHY: 62,
    QUOTE_REJECTION_REASON_FOR_BABYSITTERS: 63
  },
  BOOKING: [
    "CLEANING_BOOKING_REJECTION_REASON",
    "PAINTING_BOOKING_REJECTION_REASON",
    "POOL_CLEANING_BOOKING_REJECTION_REASON",
    "ALL_CLEANING_BOOKING_REJECTION_REASON",
    "ALL_HANDYMAN_BOOKING_REJECTION_REASON"
  ],
  BOOKING_REJECTION_REASON_OTHER: {
    HOME_CLEANING: "CLEANING_BOOKING_REJECTION_REASON_OTHER",
    HOME_PAINTING: "PAINTING_BOOKING_REJECTION_REASON_OTHER",
    POOL_CLEANING: "POOL_CLEANING_BOOKING_REJECTION_REASON_OTHER"
  },
  BOOKING_REJECTION_LABELS_MAPPING: {
    HOME_CLEANING: "CLEANING_BOOKING_REJECTION_REASON",
    HOME_PAINTING: "PAINTING_BOOKING_REJECTION_REASON",
    POOL_CLEANING: "POOL_CLEANING_BOOKING_REJECTION_REASON"
  },
  // Map all service ids with reasons
  BOOKING_REJECTION_LABELS_VIA_SERVICE_ID: {
    PM: "CLEANING_BOOKING_REJECTION_REASON",
    HP: "PAINTING_BOOKING_REJECTION_REASON",
    PCS: "POOL_CLEANING_BOOKING_REJECTION_REASON",

    DC: "ALL_CLEANING_BOOKING_REJECTION_REASON",
    WT: "ALL_CLEANING_BOOKING_REJECTION_REASON",
    CU: "ALL_CLEANING_BOOKING_REJECTION_REASON",
    BC: "ALL_CLEANING_BOOKING_REJECTION_REASON",
    SC: "ALL_CLEANING_BOOKING_REJECTION_REASON",
    WC: "ALL_CLEANING_BOOKING_REJECTION_REASON",
    ACC: "ALL_CLEANING_BOOKING_REJECTION_REASON",

    AT: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    CK: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    BB: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    MP: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    ACR: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    ACI: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    PL: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    TM: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    EL: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    FA: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    CH: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    LL: "ALL_HANDYMAN_BOOKING_REJECTION_REASON",
    HS: "ALL_HANDYMAN_BOOKING_REJECTION_REASON"
  }
};

export const INFO_MESSAGES = {
  ACCEPT_OR_REJECT_PENDING_CHANGE_REQUEST:
    "Please make sure to accept/reject a pending change request before creating this one. ",
  CHANGE_REQUEST_FLOW_TRIGGER: "This action triggers change request workflow!",
  AVAILABLE_BALANCE_LOW:
    "Time to top up! Please contact ServiceMarket to arrange payment.",
  NO_CREDIT_CARD_REGISTER:
    "Error! You don't have any credit cards in the system. Please",
  INVOICE_PAYMENT_SUCCESS:
    "Success! Your invoice payment transaction has been processed successful!",
  TOP_UP_PAYMENT_SUCCESS:
    "Success! Your top-up payment has been processed successfully! You have :balance in your available balance.",
  BOOKING_ASSIGN_TO_OTHER_PARTNER: "This booking has already been accepted"
};

export const SERVER_ERRORS = {
  SOMETHINGS_GOES_WRONG:
    "Something goes wrong, Please check if you missed something"
};

export const SUCCESS_MESSAGES = {
  BOOKING_ACCEPTED: "You have accepted booking successfully",
  TECHNICIAN_UPDATED: "Technician updated successfully",
  TECHNICIAN_CREATED: "New Technician created",
  CREDIT_CARD_ADD_SUCCESS: "Credit card successfull added"
};

export const EXPOSE_URL_FOR_CREDIT_CARD = {
  SUCCESS: DOMAIN_URL.LOCAL + "payment/view-card/success",
  CANCEL: DOMAIN_URL.LOCAL + "payment/view-card/cancel",
  DECLINE: DOMAIN_URL.LOCAL + "payment/view-card/decline"
};

export const BOOKING_SUCCESS_STATUS = {
  BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED:
    "BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED",
  BOOKING_CONFIRMATION_STATUS_ACCEPTED: "BOOKING_CONFIRMATION_STATUS_ACCEPTED"
};

export const PAYMENT_METHODS = {
  CONST_STRING_MAPPING: {
    CASH_ON_DELIVERY: "Cash on Delivery",
    CREDIT_CARD: "Credit Card",
    BOOKING_PAYMENT_METHOD_EMPTY: "Unverified Payment Method"
  },
  CONST_MAPPING: {
    CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
    CREDIT_CARD: "CREDIT_CARD",
    PAY_BY_LINK: "PAY_BY_LINK",
    BOOKING_PAYMENT_METHOD_EMPTY: "BOOKING_PAYMENT_METHOD_EMPTY"
  },
  CONST_ID_MAPPING: {
    CASH_ON_DELIVERY: 90,
    CREDIT_CARD: 89,
    PAY_BY_LINK: 288,
    BOOKING_PAYMENT_METHOD_EMPTY: 257
  }
};

export const CHANGE_REQUEST_STATUSES = {
  CONST_STRING_MAPPING: {
    CHANGE_REQUEST_STATUS_PENDING: "Pending",
    CHANGE_REQUEST_STATUS_ACCEPTED: "Accepted",
    CHANGE_REQUEST_STATUS_REJECTED: "Rejected"
  },
  CONST_MAPPING: {
    PENDING: "CHANGE_REQUEST_STATUS_PENDING",
    ACCEPTED: "CHANGE_REQUEST_STATUS_ACCEPTED",
    REJECTED: "CHANGE_REQUEST_STATUS_REJECTED"
  },
  CONST_MAPPING_V1_API: {
    PENDING: "Pending approval"
  }
};

export const BOOKING_CONFIRMATION_STATUSES = {
  CONST_STRING_MAPPING: {
    BOOKING_CONFIRMATION_STATUS_PENDING: "Pending Confirmation",
    BOOKING_CONFIRMATION_STATUS_ACCEPTED: "Accepted",
    BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED: "Late Accepted",
    BOOKING_CONFIRMATION_STATUS_REJECTED: "Rejected",
    BOOKING_CONFIRMATION_STATUS_CANCELLED: "Cancelled",
    BOOKING_EVENT_STATUS_PLANNED: "Planned",
    BOOKING_EVENT_STATUS_CANCELLED_AND_UNPAID: "Cancelled",
    BOOKING_EVENT_STATUS_CANCELLED: "Cancelled And Unpaid",
    BOOKING_EVENT_STATUS_CANCELLED_AND_PAID: "Cancelled And Paid",
    BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS: "Pending In Progress",
    BOOKING_EVENT_STATUS_CHANGE_REQUESTED: "Change Request",
    BOOKING_EVENT_STATUS_DELIVERED_AND_PAID: "Delivered and Paid",
    BOOKING_EVENT_STATUS_PENDING_CONFIRMATION: "Pending Confirmation"
  },
  CONST_MAPPING: {
    PENDING: "BOOKING_CONFIRMATION_STATUS_PENDING",
    ACCEPTED: "BOOKING_CONFIRMATION_STATUS_ACCEPTED",
    LATE_ACCEPTED: "BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED",
    REJECTED: "BOOKING_CONFIRMATION_STATUS_REJECTED",
    CANCELLED: "BOOKING_CONFIRMATION_STATUS_CANCELLED"
  },
  ID_CONST_MAPPING: {
    79: "BOOKING_CONFIRMATION_STATUS_PENDING",
    80: "BOOKING_CONFIRMATION_STATUS_ACCEPTED",
    81: "BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED",
    82: "BOOKING_CONFIRMATION_STATUS_REJECTED",
    266: "BOOKING_CONFIRMATION_STATUS_CANCELLED"
  },
  CONST_ID_MAPPING: {
    BOOKING_CONFIRMATION_STATUS_PENDING: 79,
    BOOKING_CONFIRMATION_STATUS_ACCEPTED: 80,
    BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED: 81,
    BOOKING_CONFIRMATION_STATUS_REJECTED: 82,
    BOOKING_CONFIRMATION_STATUS_CANCELLED: 266
  },
  ID_STRING_MAPPING: {
    79: "Pending Confirmation",
    80: "Accepted",
    81: "Late Accepted",
    82: "Rejected",
    266: "Cancelled"
  }
};
export const ITEMS_TO_BE_MAPPED = [
  "CASH_ON_DELIVERY",
  "PLATFORM_FEE",
  "SERVICE_SURCHARGE"
];
export const ADDITIONAL_PRICES = {
  CONST_STRING_MAPPING: {
    material_charges: "Material charges", // Common
    no_of_units: "No. of AC units", // AC Cleaning
    oxy_cleaning: "OxyClean", // AC Cleaning
    no_of_rooms: "No. of rooms", // Ants
    property_type: "Property Type", // Ants,
    CASH_ON_DELIVERY: "COD charge",
    PLATFORM_FEE: "Platform Service Fee",
    SERVICE_SURCHARGE: "Peak Surcharge"
  }
};

export const WEEKDAYS = {
  CONST_STRING_MAPPING: {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun"
  }
};

export const FEEDBACK = {
  TYPES: {
    BOOKING: {
      ONCE: "ONCE_BOOKING_CANCELLATION",
      RECURRING: "RECURRING_BOOKING_CANCELLATION"
    },
    EVENT: {
      ONCE: "ONCE_EVENT_CANCELLATION",
      RECURRING: "RECURRING_EVENT_CANCELLATION"
    }
  },
  PLATFORM: {
    OPS: "OPS"
  }
};

export const SAUDI_CITIES_CODES = {
  code: ["JED", "RUH", "DMM"]
};

export const QUOTE_STATUS = {
  0: "Reject",
  1: "Accept To Quote",
  2: "Decision Pending",
  3: "Accept Survey"
};

export const GRID = {
  RECORDS_PER_PAGE: 10,
  NAMES: {
    NEW_BOOKINGS: "New Bookings",
    SOFT_BOOKINGS: "Potential Bookings",
    ACCEPTED_BOOKINGS: "Accepted Bookings",
    ACCEPTED_AND_UNASSIGNED_BOOKINGS: "Accepted and Unassigned Bookings",
    ON_HOLD_BOOKINGS: "On-hold Bookings",
    FREEZED_BOOKINGS: "Frozen Bookings",
    CHANGE_REQUESTS: "Change Requests",
    CANCELLED_BOOKINGS: "Cancelled Bookings",
    ALL_BOOKINGS: "All Bookings",
    PLANNED_JOBS: "Planned Jobs",
    PENDING_CONFIRMATION: "Pending Delivery Confirmation",
    DELIVERED_AND_PAID: "Delivered and Paid",
    DELIVERED_AND_UNPAID: "Delivered and Unpaid",
    CANCELLED_EVENTS: "Cancelled",
    CANCELLED_AND_PAID: "Cancelled and Paid",
    ZENDESK_TICKETS: "Complaints"
  }
};

export const LOADER_CONSTANT = {
  FETCH_IN_PROGRESS: "Wait! Data is loading"
};

export const SERVICE_NAME_FOR_QUOTE_DETAIL_URL = {
  151: "babysitters",
  119: "catering",
  142: "photography"
};

export const QUOTE_OPTIONS = [
  { id: 1, value: "AED" },
  { id: 2, value: "AED per Hour" },
  { id: 3, value: "AED per Month" },
  { id: 7, value: "AED per Year" },
  { id: 8, value: "AED per CBM" }
];

export const QUOTES_STATUS_MAPPING = {
  "local-move": "local-move",
  "international-move": "international-move",
  "car-shipping": "car-shipping",
  storage: "storage",
  "cleaning-and-maid-services": "household",
  maintenance: "household",
  "pest-control": "household",
  painting: "painting",
  gardening: "household",
  "curtains-&-blinds": "curtains",
  catering: "catering",
  photography: "photography",
  "part-time-nannies-&-babysitters": "babysitters"
};

export const serviceStatus = {
  BOOKING_STATUS_NEW: 68,
  BOOKING_STATUS_BANKED: 355,
  BOOKING_CONFIRMATION_STATUS_REJECTED: 82,
  BOOKING_CONFIRMATION_STATUS_ACCEPTED: 80,
  BOOKING_STATUS_CHANGE_REQUESTED: 69,
  BOOKING_EVENT_STATUS_CHANGE_REQUESTED: 78,
  BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED: 81,
  BOOKING_CONFIRMATION_STATUS_PENDING: 79,
  BOOKING_EVENT_STATUS_PLANNED: 73,
  BOOKING_STATUS_CANCELLED: 72,
  BOOKING_STATUS_PENDING: 71,
  BOOKING_STATUS_CONFIRMED: 70,
  BOOKING_EVENT_STATUS_PENDING_CONFIRMATION: 74,
  BOOKING_EVENT_STATUS_DELIVERED_AND_PAID: 76,
  BOOKING_STATUS_UNVERIFIED_PAYMENT_METHOD: 91,
  BOOKING_STATUS_ON_HOLD: 254,
  BOOKING_STATUS_FREEZED: 264,
  BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS: 280,
  PENDING_BOOKING_CONFIRMATION: 77,
  BOOKING_EVENT_STATUS_ON_HOLD: 255
};

export const serviceConstant = {
  BOOKING_STATUS_CONFIRMED: "BOOKING_STATUS_CONFIRMED",
  BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS:
    "BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS",
  BOOKING_EVENT_STATUS_PLANNED: "BOOKING_EVENT_STATUS_PLANNED",
  BOOKING_EVENT_STATUS_IN_PROGRESS: "BOOKING_EVENT_STATUS_IN_PROGRESS",
  BOOKING_EVENT_STATUS_PENDING_CONFIRMATION:
    "BOOKING_EVENT_STATUS_PENDING_CONFIRMATION",
  BOOKING_EVENT_STATUS_DELIVERED_AND_PAID:
    "BOOKING_EVENT_STATUS_DELIVERED_AND_PAID",
  BOOKING_STATUS_CANCELLED: "BOOKING_STATUS_CANCELLED",
  BOOKING_STATUS_ON_HOLD: "BOOKING_STATUS_ON_HOLD",
  BOOKING_STATUS_FREEZED: "BOOKING_STATUS_FREEZED",
  BOOKING_EVENT_STATUS_CHANGE_REQUESTED:
    "BOOKING_EVENT_STATUS_CHANGE_REQUESTED",
  BOOKING_EVENT_STATUS_CANCELLED: "BOOKING_EVENT_STATUS_CANCELLED"
};

export const serviceCodeToStatus = {
  68: "BOOKING_STATUS_NEW",
  355: "BOOKING_STATUS_BANKED",
  82: "BOOKING_CONFIRMATION_STATUS_REJECTED",
  80: "BOOKING_CONFIRMATION_STATUS_ACCEPTED",
  69: "BOOKING_STATUS_CHANGE_REQUESTED",
  78: "BOOKING_EVENT_STATUS_CHANGE_REQUESTED",
  81: "BOOKING_CONFIRMATION_STATUS_LATE_ACCEPTED",
  79: "BOOKING_CONFIRMATION_STATUS_PENDING",
  73: "BOOKING_EVENT_STATUS_PLANNED",
  72: "BOOKING_STATUS_CANCELLED",
  71: "BOOKING_STATUS_PENDING",
  70: "BOOKING_STATUS_CONFIRMED",
  74: "BOOKING_EVENT_STATUS_PENDING_CONFIRMATION",
  76: "BOOKING_EVENT_STATUS_DELIVERED_AND_PAID",
  91: "BOOKING_STATUS_UNVERIFIED_PAYMENT_METHOD",
  254: "BOOKING_STATUS_ON_HOLD",
  255: "BOOKING_EVENT_STATUS_ON_HOLD",
  264: "BOOKING_STATUS_FREEZED"
};

export const SERVICES = {
  CONST_SERVICE_CODE_MAPPING: {
    AC_CLEANING: "ACC",
    AC_DUCT_CLEANING: "ADC",
    AC_COIL_CLEANING: "ACCC",
    ANTS: "AT",
    COCKROACHES: "CK",
    BED_BUGS: "BB",
    MOVE_IN_PEST_CONTROL: "MP",
    AC_REPAIR: "ACR",
    AC_INSTALLATION: "ACI",
    DEEP_CLEANING: "DC",
    CLEANING_AND_SANITIZATION: "CSN",
    WATER_TANK_CLEANING: "WT",
    CARPET_CLEANING: "CU",
    MATRESS_CLEANING: "BC",
    SOFA_CLEANING: "SC",
    HOME_CLEANING: "PM",
    CAR_WASH_AT_HOME: "CW",
    HOME_PAINTING: "HP",
    POOL_CLEANING: "PCS",
    WINDOW_CLEANING: "WC",
    PLUMBING: "PL",
    TV_MOUNTING: "TM",
    ELECTRICIAN: "EL",
    LIGHT_BULBS_AND_SPOTLIGHTS: "LL",
    FURNITURE_ASSEMBLY: "FA",
    CURTAIN_HANGING: "CH",
    LIGHT_FIXTURE: "LL",
    OTHER_HANDYMAN: "HS",
    LAUNDRY_SERVICE: "LDR",
    MOVE_IN_OUT_PAINTING: "MI",
    APPLIANCE_REPAIR_INSTALLATION: "HARI",
    LOCAL_MOVING: "MV",
    CARPENTER: "CP",
    LOCKSMITHS: "LS",
    PCR: "PCR",
    BABYSITTING: "BS",
    OFFICE_CLEANING: "OFFC",
    PET_GROOMING_DOGG: "DOGG",
    PET_GROOMING_CATG: "CATG",
    PET_GROOMING: "PEG",
    WOMEN_SALON: "WS",
    MEN_SALON: "WS",
    LAB_TESTS: "LT",
    DOCTOR_AT_HOME: "DH",
    NURSE_AT_HOME: "NH",
    FITNESS_AT_HOME: "FITN",
    IV_TRIPS: "DT",
    SIGNATURE_WOMEN_SALON: "WSSC"
  },
  SERVICE_CODE_TO_SERVICE_NAME: {
    PM: "Home Cleaning",
    HP: "Home Painting",
    PCS: "Pool Cleaning",
    WC: "Window Cleaning"
    // PL: "Plumbing"
  }
};

export const QUOTES_NAME_TO_ID = {
  parentService: {
    LOCAL_MOVE: 1,
    INTERNATIONAL_MOVE: 2,
    CAR_SHIPPING: 3,
    STORAGE: 4,
    CLEANING_AND_MAID_SERVICES: 6,
    MAINTENANCE: 7,
    PEST_CONTROL: 8,
    PAINTING: 9,
    GARDENING: 10,
    CAR_INSURANCE: 101,
    CURTAINS_AND_BLNDS: 110,
    CATERING: 119,
    PHOTOGRAPHY: 142,
    PART_TIME_NANNIES_AND_BABYSITTERS: 151
  },
  subService: {
    DEEP_CLEANING: 39,
    OFFICE_CLEANING: 40,
    HOME_CLEANING: 41,
    PART_TIME_CLEANING: 0,
    FULL_TIME_MAID: 42,
    CARPET_CLEANING: 45,
    MATTRESS_CLEANING: 104,
    WATER_TANK_CLEANING: 172,
    POOL_CLEANING: 173,
    SOFA_CLEANING: 174,
    WINDOW_CLEANING: 44,
    OTHER_CLEANING: 46,
    CLEANING_AND_SANITIZATION: 178
  }
};

export const quoteStatus = {
  REJECT: 0,
  ACCEPT_TO_QUOTE: 1,
  DECISION_PENDING: 2,
  ACCEPT_SURVEY: 3
};

export const SHARE_CUSTOMER_INFO = {
  UNSPECIFIED: 0,
  PHONE_AND_EMAIL: 1,
  EMAIL: 2,
  DO_NOT_SHARE: 3
};

export const MAIDS = {
  STATUS: {
    ACTIVE: "Active",
    IN_ACTIVE: "Inactive"
  },
  STATUS_CHECK: {
    ACTIVE: "ACTIVE",
    IN_ACTIVE: "IN_ACTIVE"
  },
  STATUS_DROPDOWN_MAPPING: {
    ACTIVE: 1,
    IN_ACTIVE: 2
  },
  PREFERENCE_STATUS: {
    PREFERRED: "PREFERRED",
    NOT_PREFERRED: "NOT_PREFERRED",
    BLACKLISTED: "BLACKLIST"
  },
  PREFERENCE_STATUS_TEXT: {
    PREFERRED: "Preferred Cleaner",
    NOT_PREFERRED: "Not Preferred Cleaner",
    BLACKLISTED: "Blacklisted Cleaner"
  },
  REDIRECT_DELAY: 4000,
  IMAGE_FILETYPES: ["jpg", "jpeg", "png", "bmp", "gif"],
  MAID_IMAGE_CONFIG: {
    WIDTH: 150,
    HEIGHT: 150,
    MAID_IMAGE_SIZE: 1, //in MB's
    SIZE_TOLERANCE: 2
  },
  INVALID_FILETYPE_MSG:
    "Invalid file uploaded. Please upload an image with jpg, jpeg, png, bmp, or gif extension.",
  INVALID_FILEDIMENSION_MSG:
    "Invalid image dimensions. Please upload a square image.",
  INVALID_FILESIZE_MSG: "Invalid image size. Please upload an image less than ",
  FILTER_TEXTBOX_PLACEHOLDER: "Start typing name..."
};

export const CHANGE_REQUEST = {
  STATUS_MAPPING: {
    PENDING: "Pending approval",
    REJECTED: "Rejected",
    ACCEPTED: "Accepted"
  },
  STATUS: {
    ACCEPTED: "CHANGE_REQUEST_STATUS_ACCEPTED",
    REJECTED: "CHANGE_REQUEST_STATUS_REJECTED"
  },
  DAY_MAPPING: {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday"
  }
};

export const BOOKING_FREQUENCY = {
  ID_MAPPING: {
    65: "Once",
    66: "Every Week",
    67: "Every 2 Weeks",
    240: "Multiple Times a Week",
    261: "Every Month",
    262: "Every 3 Months",
    263: "Every 6 Months"
  },
  CONST_MAPPING: {
    ONCE: 65,
    EVERY_WEEK: 66,
    EVERY_TWO_WEEKS: 67,
    MULTIPLE_TIMES: 240,
    EVERY_MONTH: 261,
    EVERY_3_MONTH: 262,
    EVERY_6_MONTH: 263
  },
  CONST_MAPPING_LABELS: {
    ONCE: "Once",
    EVERY_WEEK: "Every Week",
    EVERY_TWO_WEEKS: "Every 2 Weeks",
    MULTIPLE_TIMES: "Multiple Times a Week",
    EVERY_MONTH: "Every Month",
    EVERY_3_MONTH: "Every 3 Months",
    EVERY_6_MONTH: "Every 6 Months"
  }
};

export const SERVICE_DETAILS_ID_TO_VALUE = {
  141: "White",
  142: "Off-white",
  143: "Color",
  144: "Mixed",
  145: "Villa",
  146: "Apartment",
  147: "Studio",
  148: "1BR",
  149: "2BR",
  150: "3BR",
  151: "4BR",
  152: "5BR",
  153: "1BR",
  154: "2BR",
  155: "3BR",
  156: "4BR",
  157: "5BR",
  158: "Maid's room",
  159: "Study room",
  160: "Store room",
  161: "Balcony",
  162: "I need ceilings painted",
  163: "I don't need ceilings painted",
  171: "Furnished",
  172: "Unfurnished",
  173: "Water Damage",
  174: "No water damage",
  180: "Yes I need paint",
  181: "No I have paint",
  182: "I have high ceilings",
  183: "I don't have high ceilings",
  184: "All rooms",
  185: "Individual rooms",
  186: "Individual walls",
  187: "Other"
};

export const SERVICE_DETAILS = {
  CONST_STRING_MAPPING: {
    TV_MOUNTING: {
      "1-54": "Below 55 inches",
      "55-64": "Above 55 inches",
      "65-999": "Above 65 inches"
    },
    MATTRESS_CLEANING: {
      MATTRESS_SIZE: {
        no_of_king_mattress_for_shampoo_cleaning: "King Size",
        no_of_king_mattress_for_steam_cleaning: "King Size",
        no_of_queen_mattress_for_shampoo_cleaning: "Queen Size",
        no_of_queen_mattress_for_steam_cleaning: "Queen Size",
        no_of_single_mattress_for_shampoo_cleaning: "Single Size",
        no_of_single_mattress_for_steam_cleaning: "Single Size",
        no_of_pillow_for_shampoo_cleaning: "Pillow/ Cushions",
        no_of_pillow_for_steam_cleaning: "Pillow/ Cushions"
      },
      CLEANING_METHOD: {
        no_of_king_mattress_for_shampoo_cleaning: "Shampoo",
        no_of_king_mattress_for_steam_cleaning: "Steam",
        no_of_queen_mattress_for_shampoo_cleaning: "Shampoo",
        no_of_queen_mattress_for_steam_cleaning: "Steam",
        no_of_single_mattress_for_shampoo_cleaning: "Shampoo",
        no_of_single_mattress_for_steam_cleaning: "Steam",
        no_of_pillow_for_shampoo_cleaning: "Shampoo",
        no_of_pillow_for_steam_cleaning: "Steam"
      }
    },
    SOFA_CLEANING: {
      CLEANING_METHOD: {
        no_of_seat_for_shampoo_cleaning: "Shampoo",
        no_of_seat_for_steam_cleaning: "Steam"
      }
    },
    CAR_WASH_AT_HOME: {
      SEDAN: "Sedan",
      SUV: "SUV",
      EXTERIOR_CLEANING_ONLY: "Exterior only",
      INTERIOR_EXTERIOR_CLEANING: "Interior + Exterior"
    }
  }
};

export const MOMENT_CONST = {
  DATE_TIME_YEAR_HOUR_MIN_AMPM: "DD-MM-YYYY hh:MM A"
};
export const isACCleaingService = serviceCode =>
  [
    SERVICES.CONST_SERVICE_CODE_MAPPING.AC_CLEANING,
    SERVICES.CONST_SERVICE_CODE_MAPPING.AC_DUCT_CLEANING,
    SERVICES.CONST_SERVICE_CODE_MAPPING.AC_COIL_CLEANING
  ].includes(serviceCode);

export function getFirebaseConfig(env) {
  switch (env) {
    case "production":
      return {
        apiKey: "AIzaSyByyJnch4EyJcUDfDNIKkCFi3OWHOIy1q0",
        authDomain: "movesouq-cecfc.firebaseapp.com",
        databaseURL: "https://movesouq-cecfc.firebaseio.com",
        projectId: "movesouq-cecfc",
        storageBucket: "movesouq-cecfc.appspot.com",
        messagingSenderId: "343478171408",
        appId: "1:343478171408:web:deec24b272d1a85e34d795",
        measurementId: "G-FFXF1MPZ0H"
      };
    default: {
      return {
        apiKey: "AIzaSyCZHvDG8DQKx520SCPi5qON0V9btOcZY6g",
        authDomain: "service-market-debug.firebaseapp.com",
        databaseURL: "https://service-market-debug.firebaseio.com",
        projectId: "service-market-debug",
        storageBucket: "service-market-debug.appspot.com",
        messagingSenderId: "449837965633",
        appId: "1:449837965633:web:eeab37aef90cba4ddbc1c0"
      };
    }
  }
}
export function getFirebaseVapidID(env) {
  switch (env) {
    /*case "test1":
          return "B0BECB46-D722-4ED1-BCCB-D67BF599BAE9";
      case "test2": {
          return "B0BECB46-D722-4ED1-BCCB-D67BF599BAE9";
      }*/
    case "production":
      return "BGpYeDCp0Liad91Sii_xtZE_cCZNif_PlJfK7UwwesE6JPM6F8siwc766Fi60_Qfasw7lvvVv4WrsboZUPQKZqo";
    default: {
      return "BG9V9VI4h1xuSM-b7zgl_wc7nE4mch00dajkDecNZpu9a9JiGD82CoHZ87BdZjw1WTukJtZox72zjSAlvSButJ8";
    }
  }
}
export function getFirebaseSWFilename(env) {
  switch (env) {
    case "production":
      return "firebase-messaging-prod.js";
    default: {
      return "firebase-messaging-sw.js";
    }
  }
}
export const isContactInformationShow = serviceCode =>
  [
    SERVICES.CONST_SERVICE_CODE_MAPPING.PCR,
    SERVICES.CONST_SERVICE_CODE_MAPPING.DOCTOR_AT_HOME,
    SERVICES.CONST_SERVICE_CODE_MAPPING.NURSE_AT_HOME,
    SERVICES.CONST_SERVICE_CODE_MAPPING.LAB_TESTS,
    SERVICES.CONST_SERVICE_CODE_MAPPING.FITNESS_AT_HOME,
    SERVICES.CONST_SERVICE_CODE_MAPPING.IV_TRIPS
  ].includes(serviceCode);

export const isHealthServices = serviceCode =>
  [
    SERVICES.CONST_SERVICE_CODE_MAPPING.PCR,
    SERVICES.CONST_SERVICE_CODE_MAPPING.DOCTOR_AT_HOME,
    SERVICES.CONST_SERVICE_CODE_MAPPING.NURSE_AT_HOME,
    SERVICES.CONST_SERVICE_CODE_MAPPING.LAB_TESTS,
    SERVICES.CONST_SERVICE_CODE_MAPPING.IV_TRIPS
  ].includes(serviceCode);
export const isOnlyDryCleaningSku = props => {
  const serviceDetail = props.serviceDetail || props.serviceDetails;
  if (serviceDetail) {
    const slug = serviceDetail.slug || [];
    return (
      slug.length &&
      slug.filter(({ id }) => id === DRY_CLEANING_PRODUCT_ID).length ===
        slug.length
    );
  }
};

export const isDeepPestControl = serviceCode =>
  [
    SERVICES.CONST_SERVICE_CODE_MAPPING.MOVE_IN_PEST_CONTROL,
    SERVICES.CONST_SERVICE_CODE_MAPPING.LAUNDRY_SERVICE
  ].includes(serviceCode);
export const DEVICE_TYPES = {
  SMILES: "smiles",
  PORTAL: "portal",
  ANDROID: "android",
  IOS: "iOS"
};
