export function getEnvBaseDomainURL(env) {
  switch (env) {
    case "test1":
      return "https://uat1-partners.servicemarket.com/";
    case "test2": {
      return "https://autoscheduler-uat1-api.servicemarket.com/";
    }
    case "test3":
      return "https://uat3-api.servicemarket.com/";
    case "android":
      return "https://and-api.servicemarket.com/";
    case "ios":
      return "https://ios-api.servicemarket.com/";
    case "aratech":
      return "https://aratech-api.servicemarket.com/";
    case "vagrant":
      return "https://local-api.servicemarket.com:9443/";
    case "production":
      return "https://partners.servicemarket.com/";
    default: {
      return window.location.origin + "/";
    }
  }
}
export function getSendBirdAppId(env) {
  switch (env) {
    /*case "test1":
            return "B0BECB46-D722-4ED1-BCCB-D67BF599BAE9";
        case "test2": {
            return "B0BECB46-D722-4ED1-BCCB-D67BF599BAE9";
        }*/
    case "production":
      return "FC3F57BB-3699-4A19-B995-7CEEDC264AFA";
    default: {
      return "B0BECB46-D722-4ED1-BCCB-D67BF599BAE9";
    }
  }
}
export function getSendBirdParterAppId(env) {
  switch (env) {
    case "production":
      return "3795B9CB-6FD1-4583-ABB3-0ECF1C23F1D5";
    default: {
      return "C3F96FF1-C3C8-4641-A236-5F0235734E2B";
    }
  }
}
export function getEnvBaseAPIURL(env) {
  switch (env) {
    case "test1":
      return "https://uat1-api.servicemarket.com/";
    case "test2": {
      return "https://autoscheduler-uat1-api.servicemarket.com/";
    }
    case "test3":
      return "https://uat3-api.servicemarket.com/";
    case "android":
      return "https://and-api.servicemarket.com/";
    case "ios":
      return "https://ios-api.servicemarket.com/";
    case "aratech":
      return "https://aratech-api.servicemarket.com/";
    case "vagrant":
      return "https://local-api.servicemarket.com:9443/";
    case "production":
      return "https://api.servicemarket.com/";
    default: {
      return "https://uat1-api.servicemarket.com/";
    }
  }
}

export function getEnvBaseIntervalTime(env) {
  switch (env) {
    case "test1":
      return 600000;
    case "production":
      return 600000;
    default: {
      return 600000;
    }
  }
}

export function getEnvForVersion() {
  switch (process.env.REACT_APP_ENV) {
    case "test1":
      return "-uat";
    case "production":
      return "";
    default: {
      return "-uat";
    }
  }
}

export function getEvtBasedGoogleAPIKey() {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "AIzaSyC30efNwDx0GPKtVLAuoMK0BF_dzDZ_8tA";
    default: {
      return "AIzaSyBUVnBcrD29TxvCX7qU7F2wpaFiHhkBQT8";
    }
  }
}
