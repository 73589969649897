import React from "react";
import { Route, Redirect } from "react-router-dom";

import { store } from "../store";
import { loadState } from "../storage/localStorage";

const checkUserAuthetication = (props) => {
  /*let pathnames = []
  if(props.location && props.location.pathname){
    pathnames = props.location.pathname.split("/").filter(item => item !== "");
  }
  if(pathnames.includes("complete-registration")){
    return true;
  }else*/ if (
    store.getState().loginReducer.isLoggedIn &&
    JSON.stringify(loadState()) !== "{}"
  ) {
    return true;
  }
  return false;
};

const redirect = props => {
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location }
      }}
    />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        checkUserAuthetication(props) ? <Component {...props} /> : redirect(props)
      }
    />
  );
};
