import React, { Component, useState, useEffect, useMemo } from "react";
import OneSignal from "react-onesignal";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import axios from "axios";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./store";
import { PrivateRoute } from "./authentication";
import { APP, APP_ROUTES, ERRORS, PLAYER_ID } from "./constants/APP_CONSTANTS";
import { OneSignalInit } from "./NotificationCenter/helper/OneSignalHelper";
import NotificationService from "./NotificationCenter/modal/NotificationService";
import { LocalStorage } from "./storage/localStorageService";
import { loadState } from "./storage/localStorage";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./containers/Login/LoginContainer"),
  loading
});
const ForgetPassword = Loadable({
  loader: () => import("./components/Login/ForgetPassword"),
  loading
});
const ResetPassword = Loadable({
  loader: () => import("./components/Login/ResetPassword"),
  loading
});
const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});
const CompleteRegistration = Loadable({
  loader: () =>
    import("./components/complete-registration/CompleteRegistration"),
  loading
});
axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const parseError = JSON.parse(JSON.stringify(err));
    if (
      parseError &&
      parseError.response &&
      parseError.response.status === ERRORS.CODES.UNAUTHORIZED_ACCESS
    ) {
      localStorage.clear();
      window.location.href = APP_ROUTES.LOGIN;
    }
    return Promise.reject(err);
  }
);
const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;
const App = () => {
  const [initialized, setInitialized] = useState(false);
  const [tokenUpdated, setTokenUpdated] = useState(false);

  const ls = useMemo(() => {
    return new LocalStorage();
  }, []);
  const localStorageProps = loadState();

  const notificationService = useMemo(() => {
    return new NotificationService();
  }, []);
  const clickEventListener = event => {
    console.log(`click event: ${event}`);
  };

  useEffect(() => {
    if (isSupported()) {
      Notification.requestPermission().then(permission => {
        if (permission === "granted")
          OneSignalInit(() => {
            setInitialized(true);
            if (
              "Notification" in window &&
              Notification.permission === "granted"
            ) {
              let deviceId = ls.get(PLAYER_ID, null);
              if (OneSignal.User.PushSubscription.id && !deviceId) {
                let subscriptionID = OneSignal.User.PushSubscription.id;
                ls.set({ PLAYER_ID: subscriptionID });
              } else {
                console.log("deviceId from local", deviceId);
              }
            } else {
              console.log("deviceId from local else");
              ls.set(PLAYER_ID, null);
            }
          }, []);

        const listener = event => {
          // Get the subscription id from the event
          if (event.current.id) {
            let subscriptionID = event.current.id;
            window.deviceId = subscriptionID;
            console.log("subscriptionID", subscriptionID);
            ls.set({ PLAYER_ID: subscriptionID });
          }
        };

        OneSignal.User.PushSubscription.addEventListener("change", listener);
        return () => {
          OneSignal.User.PushSubscription.removeEventListener(
            "change",
            listener
          );
        };
      });
    }
  }, []);

  const userAuthItem =
    localStorageProps && localStorageProps.loginReducer
      ? localStorageProps.loginReducer.user
      : null;
  //console.log("userAuthItem", localStorageProps);

  /*useEffect(() => {
    if (userAuthItem && userAuthItem.access_token && !tokenUpdated) {
      let deviceUUID = ls.get(PLAYER_ID, null);
      if (deviceUUID) {
        setTokenUpdated(true);
        OneSignal.login(deviceUUID);
        notificationService.registerDevice(deviceUUID).then(res => {
          console.log("registerDevice res", res);
        });
      }
    }
  });*/
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route
            exact
            path="/forget-password"
            name="Login Page"
            component={ForgetPassword}
          />
          <Route
            exact
            path="/reset/:token"
            name="Login Page"
            component={ResetPassword}
          />
          <Route
            exact
            path={APP_ROUTES.COMPLETE_REGISTRATION}
            name="Complete Registration"
            component={CompleteRegistration}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            component={Register}
          />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          {/* <Route path="/" name="Home" component={DefaultLayout} /> */}
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
