import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import { createRoot } from "react-dom/client";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { APP } from "./constants/APP_CONSTANTS";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

//ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/" + APP.FIREBASE_SW_FILE_NAME).then(
    function(response) {
      // Service worker registration done
      console.log("Registration Successful");
    },
    function(error) {
      // Service worker registration failed
      console.log("Registration Failed", error);
    }
  );
}
