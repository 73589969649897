export default {
  REQUEST_INITIATE: "REQUEST_INITIATE",
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOG_OUT: "LOG_OUT",

  // Dashboard
  FUTURE_EVENT_SUCCESS: "FUTURE_EVENT_SUCCESS",
  FUTURE_EVENT_FAIL: "FUTURE_EVENT_FAIL",
  BOOKING_COUNT_SUCCESS: "BOOKING_COUNT_SUCCESS",
  BOOKING_COUNT_FAIL: "BOOKING_COUNT_FAIL",
  BOOKING_COUNT_INITIATE: "BOOKING_COUNT_INITIATE",
  LEAD_COUNT_SUCCESS: "LEAD_COUNT_SUCCESS",
  LEAD_COUNT_INITIATE: "LEAD_COUNT_INITIATE",
  LEAD_COUNT_FAIL: "LEAD_COUNT_FAIL",
  TOTAL_BILL_SUCCESS: "TOTAL_BILL_SUCCESS",
  TOTAL_BILL_FAIL: "TOTAL_BILL_FAIL",
  MONTHLY_LEAD_COUNT_SUCCESS: "MONTHLY_LEAD_COUNT_SUCCESS",
  MONTHLY_LEAD_COUNT_FAIL: "MONTHLY_LEAD_COUNT_FAIL",
  BOOKING_GROSS_REVENUE_INITIATE: "BOOKING_GROSS_REVENUE_INITIATE",
  BOOKING_GROSS_REVENUE_SUCCESS: "BOOKING_GROSS_REVENUE_SUCCESS",
  BOOKING_GROSS_REVENUE_FAIL: "BOOKING_GROSS_REVENUE_FAIL",
  RATINGS_SUCCESS: "RATING_SUCCESS",
  RATINGS_FAIL: "RATING_FAIL",
  LEAD_TYPE_SUCCESS: "LEAD_TYPE_SUCCESS",
  LEAD_TYPE_FAIL: "LEAD_TYPE_FAIL",

  // Bookings and related
  BOOKING_INIT: "BOOKING_INIT",
  GET_BOOKING_SUCCESSS: "GET_BOOKING_SUCCESSS",
  GET_BOOKING_FAIL: "GET_BOOKING_FAIL",
  BOOKING_DETAILS_SUCCESS: "BOOKING_DETAILS_SUCCESS",
  BOOKING_DETAILS_FAIL: "BOOKING_DETAILS_FAIL",
  BOOKING_DETAILS_RESET: "BOOKING_DETAILS_RESET",
  BOOKING_CHANGE_REQUEST_SUCCESS: "BOOKING_CHANGE_REQUEST_SUCCESS",
  BOOKING_CHANGE_REQUEST_FAIL: "BOOKING_CHANGE_REQUEST_FAIL",
  GET_MAID_SUCCESS: "GET_MAID_SUCCESS",
  GET_MAID_FAIL: "GET_MAID_FAIL",
  CLEANER_ASSIGN_SUCCESS: "CLEANER_ASSIGN_SUCCESS",
  CLEANER_ASSIGN_FAIL: "CLEANER_ASSIGN_FAIL",

  // Events
  EVENT_DETAILS_SUCCESS: "EVENT_DETAILS_SUCCESS",
  EVENT_DETAILS_FAIL: "EVENT_DETAILS_FAIL",
  EVENT_CHANGE_REQUEST_SUCCESS: "EVENT_CHANGE_REQUEST_SUCCESS",
  EVENT_CHANGE_REQUEST_FAIL: "EVENT_CHANGE_REQUEST_FAIL",
  EVENT_DETAILS_RESET: "EVENT_DETAILS_RESET",

  // Quotes Requestf
  QUOTES_REQUEST_INIT: "QUOTES_REQUEST_INIT",
  GET_QUOTES_REQUEST_FAIL: "GET_QUOTES_REQUEST_FAIL",
  GET_QUOTES_REQUEST_SUCCESSS: "GET_QUOTES_REQUEST_SUCCESSS",
  SEARCH_QUOTES_REQUEST_FROM_FETCHED_DATA:
    "SEARCH_QUOTES_REQUEST_FROM_FETCHED_DATA",
  QUOTES_DETAILS_SUCCESS: "QUOTES_DETAILS_SUCCESS",
  QUOTES_DETAILS_FAIL: "QUOTES_DETAILS_FAIL",
  QUOTES_FILE_UPLOAD_PROGRESS: "QUOTES_FILE_UPLOAD_PROGRESS",
  QUOTES_FILE_UPLOAD_DONE: "QUOTES_FILE_UPLOAD_DONE",
  QUOTES_FILE_UPLOAD_FAIL: "QUOTES_FILE_UPLOAD_FAIL",
  REMOVE_UPLOADED_FILE_FROM_STATE: "REMOVE_UPLOADED_FILE_FROM_STATE",
  QUOTES_ACCEPTED_SUCCESS: "QUOTES_ACCEPTED_SUCCESS",
  QUOTES_ACCEPTED_FAIL: "QUOTES_ACCEPTED_FAIL",

  // Cleaner stuff
  REGISTER_CLEANER_INIT: "REGISTER_CLEANER_INIT",
  REGISTER_CLEANER_SUCCESS: "REGISTER_CLEANER_SUCCESS",
  REGISTER_CLEANER_FAIL: "REGISTER_CLEANER_FAIL",

  CLEANER_REQUEST_INIT: "CLEANER_REQUEST_INIT",
  CLEANER_LIST_SUCCESS: "CLEANER_LIST_SUCCESS",
  CLEANER_LIST_FAIL: "CLEANER_LIST_FAIL",
  CLEANER_DETAILS_SUCCESS: "CLEANER_DETAILS_SUCCESS",
  CLEANER_DETAILS_FAIL: "CLEANER_DETAILS_FAIL",
  SEARCH_CLEANER_FROM_FETCHED_DATA: "SEARCH_CLEANER_FROM_FETCHED_DATA",
  // Promotion
  PROMOTION_LEAD_INIT: "PROMOTION_LEAD_INIT",
  GET_PROMOTION_LEAD_SUCCESSS: "GET_PROMOTION_LEAD_SUCCESSS",
  GET_PROMOTION_LEAD_FAIL: "GET_PROMOTION_LEAD_FAIL",
  LEAD_DETAILS_SUCCESS: "LEAD_DETAILS_SUCCESS",
  LEAD_DETAILS_FAIL: "LEAD_DETAILS_SUCCESS",
  SEARCH_LEAD_FROM_FETCHED_DATA: "SEARCH_LEAD_FROM_FETCHED_DATA",

  // Profile
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  PROFILE_FAIL: "PROFILE_FAIL",

  // Reports
  MY_BILLING_INIT: "MY_BILLING_INIT",
  MY_BILLING_SUCCESS: "MY_BILLING_SUCCESS",
  MY_BILLING_FAIL: "MY_BILLING_FAIL",
  AVAILABLE_BALANCE_SUCCESS: "AVAILABLE_BALANCE_SUCCESS",
  AVAILABLE_BALANCE_FAIL: "AVAILABLE_BALANCE_FAIL",
  SEARCH_BILL_FROM_FETCHED_DATA: "SEARCH_BILL_FROM_FETCHED_DATA",

  // Payment
  PAYMENT_MADE_SUCCESS: "PAYMENT_MADE_SUCCESS",
  PAYMENT_MADE_FAIL: "PAYMENT_MADE_FAIL",
  SAVED_CARD_SUCCESS: "SAVED_CARD_SUCCESS",
  SAVED_CARD_FAIL: "SAVED_CARD_FAIL",
  REMOVE_CARD_SUCCESS: "REMOVE_CARD_SUCCESS",
  REMOVE_CARD_FAIL: "REMOVE_CARD_FAIL",
  TRANSACTION_HISTORY_SUCCESS: "TRANSACTION_HISTORY_SUCCESS",
  TRANSACTION_HISTORY_FAIL: "TRANSACTION_HISTORY_FAIL",
  BOOKING_COUNTS: "BOOKING_COUNTS",
  CHAT_COUNTS: "CHAT_COUNTS",

  // Partner flags
  LOADING_PARTNER_FLAGS: "LOADING_PARTNER_FLAGS"
};
