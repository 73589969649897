import CONSTANTS from "../constants/actionConstants";

const initialState = {
  fetchError: "",
  quotesRequest: [],
  allLocalSavedQuotedRequest: [],
  fileUploadList: [],
  quotesDetails: {},
  quotesDetailsSuccess: false,
  fetchSuccess: false
};

const quotesRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.QUOTES_REQUEST_INIT:
      return {
        ...state,
        fetchError: "",
        quotesDetails: {},
        quotesDetailsSuccess: false,
        quotesRequest: [],
        fetchSuccess: false
      };

    case CONSTANTS.GET_QUOTES_REQUEST_SUCCESSS: {
      return {
        ...state,
        fetchError: "",
        quotesRequest: action.quotesRequest,
        allLocalSavedQuotedRequest: action.quotesRequest,
        fetchSuccess: true
      };
    }

    case CONSTANTS.GET_QUOTES_REQUEST_FAIL:
      return {
        ...state,
        fetchError: action.error,
        quotesRequest: [],
        fetchSuccess: false
      };
    case CONSTANTS.QUOTES_ACCEPTED_SUCCESS: {
      return {
        ...state,
        acceptedSuccess: true
      };
    }

    case CONSTANTS.QUOTES_ACCEPTED_FAIL:
      return {
        ...state,
        acceptedSuccess: false
      };
    case CONSTANTS.SEARCH_QUOTES_REQUEST_FROM_FETCHED_DATA: {
      const searchedRecords = [];
      if (action.searchKeyword === null) {
        return {
          ...state,
          fetchError: "",
          quotesRequest: state.allLocalSavedQuotedRequest,
          fetchSuccess: true
        };
      } else {
        state.allLocalSavedQuotedRequest.forEach(item =>
          Object.keys(item).map(key => {
            if (item[key] && typeof item[key] === "string") {
              if (
                item[key]
                  .toLowerCase()
                  .search(action.searchKeyword.toLowerCase()) !== -1
              ) {
                searchedRecords.push(item);
              }
            }
            return searchedRecords;
          })
        );
        return {
          ...state,
          fetchError: "",
          quotesRequest: searchedRecords,
          fetchSuccess: true
        };
      }
    }

    case CONSTANTS.QUOTES_DETAILS_SUCCESS: {
      return {
        ...state,
        quotesDetails: action.quotesDetails,
        quotesDetailsSuccess: true
      };
    }

    case CONSTANTS.QUOTES_DETAILS_FAIL:
      return {
        ...state,
        quotesDetails: {},
        quotesDetailsSuccess: false
      };

    case CONSTANTS.QUOTES_FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: true
      };

    case CONSTANTS.QUOTES_FILE_UPLOAD_DONE: {
      const fileList = state.fileUploadList;
      fileList.push({ quoteAttachedDocument: action.filePath });
      return {
        ...state,
        uploadProgress: false,
        fileUploadList: fileList
      };
    }

    case CONSTANTS.QUOTES_FILE_UPLOAD_FAIL:
      return {
        ...state,
        uploadProgress: false
      };

    case CONSTANTS.REMOVE_UPLOADED_FILE_FROM_STATE:
      return {
        ...state,
        uploadProgress: false,
        fileUploadList: []
      }
    default:
      return state;
  }
};
export default quotesRequestReducer;
