import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
// import promiseMiddleware from 'redux-promise-middleware';
import throttle from "lodash/throttle";
import { loadState, saveState } from "../storage/localStorage";
import reducer from "../reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];
if (process.env.REACT_APP_ENV !== "production") {
  middleware.push(createLogger());
}


const store = createStore(
  reducer,
  loadState(),
  composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(
  throttle(() => {
    saveState({
      loginReducer: store.getState().loginReducer
    });
  }, 3000)
);

export { store };
