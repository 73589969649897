import CONSTANTS from "../constants/actionConstants";

const initialState = {
  fetchError: "",
  cleanersList: [],
  allLocalSaveCleanerList : [],
  cleanerDetails: {},
  cleanerDetailsSuccess: false,
  fetchSuccess: false
};

const cleanerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.CLEANER_REQUEST_INIT:
      return {
        ...state,
        fetchError: "",
        cleanerDetailsSuccess: false,
        fetchSuccess: false
      };

    case CONSTANTS.CLEANER_LIST_SUCCESS: {
      return {
        ...state,
        cleanersList: action.cleanersList,
        allLocalSaveCleanerList: action.cleanersList,
        fetchSuccess: true
      };
    }

    case CONSTANTS.CLEANER_LIST_FAIL:
      return {
        ...state,
        cleanersList: [],
        fetchSuccess: false
      };

    case CONSTANTS.CLEANER_DETAILS_SUCCESS: {
      return {
        ...state,
        cleanerDetails: action.cleanerDetails,
        cleanerDetailsSuccess: true
      };
    }

    case CONSTANTS.CLEANER_DETAILS_FAIL:
      return {
        ...state,
        cleanerDetails: {},
        cleanerDetailsSuccess: false
      };
    case CONSTANTS.SEARCH_CLEANER_FROM_FETCHED_DATA: {
      const searchedRecords = [];
      if (action.searchKeyword === null) {
        return {
          ...state,
          fetchError: "",
          cleanersList: state.allLocalSaveCleanerList,
          fetchSuccess: true
        };
      } else {
        state.cleanersList.forEach(item =>
          Object.keys(item).map(key => {
            if (item[key] && typeof item[key] === "string") {
              if (
                item[key]
                  .toLowerCase()
                  .search(action.searchKeyword.toLowerCase()) !== -1
              ) {
                searchedRecords.push(item);
              }
            }
            return searchedRecords;
          })
        );
        return {
          ...state,
          fetchError: "",
          cleanersList: searchedRecords,
          fetchSuccess: true
        };
      }
    }
    default:
      return state;
  }
};
export default cleanerReducer;
