import { combineReducers } from "redux";
import loginReducer from "./LoginReducer";
import dashboardReducer from "./DashboardReducer";
import bookingReducer from "./BookingReducer";
import cleanerReducer from "./CleanerReducer";
import promotionReducer from "./PromotionReducer";
import profileReducer from "./ProfileReducer";
import reportReducer from "./ReportReducer";
import paymentReducer from "./PaymentReducer";
import quotesRequestReducer from "./QuotesRequestReducer";
import eventReducer from "./EventReducer";
import partnerDataReducer from "./PartnerDataReducer";

export default combineReducers({
  loginReducer,
  dashboardReducer,
  bookingReducer,
  cleanerReducer,
  promotionReducer,
  profileReducer,
  reportReducer,
  paymentReducer,
  quotesRequestReducer,
  eventReducer,
  partnerDataReducer
});
