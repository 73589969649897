import OneSignal from "react-onesignal";
import { APP, APP_ROUTES } from "../../constants/APP_CONSTANTS";
const getOneSignalAppId = () => {
  let appId = "",
    safariWebId = "";
  const env = process.env.REACT_APP_ENV;
  /*if (window?.location?.href.includes("localhost")) {
    appId = "e11f30a0-181e-4a1f-a0af-6285bcc2a297";
    safariWebId = "web.onesignal.auto.2510e921-2066-4b3b-be25-8e0e09bd836c";
  } else*/
  if (env === "production") {
    appId = "9b05ffaf-476d-4b26-8c2b-b45cce35afbf";
    safariWebId = "web.onesignal.auto.36c77d1a-9357-4d49-966b-0de749957ba2";
  } else {
    appId = "e30a69af-bba6-49cf-94ab-783dfa2b5775";
    safariWebId = "web.onesignal.auto.64063cbd-bb2e-4678-8d54-7a11e3cbaa94";
  }
  return { appId: appId, safariWebId: safariWebId };
};
export const OneSignalInit = (callBackFn = () => {}) => {
  const { appId, safariWebId } = getOneSignalAppId();
  OneSignal.init({
    appId: appId,
    safari_web_id: safariWebId,
    /*notifyButton: {
      enable: true
    },
    notificationClickHandlerMatch: "origin",
    notificationClickHandlerAction: "focus",*/
    allowLocalhostAsSecureOrigin: true,
    serviceWorkerPath: APP.FIREBASE_SW_FILE_NAME,
    //notifyButton
    promptOptions: {
      slidedown: {
        type: "push",
        autoPrompt: false,
        enabled: false,
        actionMessage:
          "Enable notifications to receive updates on new and existing bookings.",
        acceptButtonText: "Yes!",
        cancelButtonText: "No"
      },
      welcomeNotification: {
        title: "One Signal",
        message: "Thanks for subscribing!"
      }
    }
  }).then(() => {
    OneSignal.Slidedown.promptPush();
    callBackFn();
    /*OneSignal.Notifications.setDefaultUrl(
      window.location.origin + "/dashboard"
    );*/
  });
};
export const OneSignalClickEventListener = event => {
  console.log("OneSignal: notification clicked:", event);
  if (event.notification && event.notification.additionalData) {
    const {
      notification_event_constant,
      service_code,
      booking_id
    } = event.notification.additionalData;
    if (["CREATED", "CANCELLED"].includes(notification_event_constant)) {
      //"/bookings/:bookingType/details/:serviceCode/:bookingId"
      window.location.href = APP_ROUTES.BOOKINGS.DETAILS.replace(
        ":bookingType",
        "open"
      )
        .replace(":bookingId", booking_id)
        .replace(":serviceCode", service_code);
    }
  } /* else {
    console.log("OneSignal: Else", event);
    window.location.href = APP_ROUTES.NOTIFICATION_CENTER.LIST;
  }*/
};
