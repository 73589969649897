import CONSTANTS from "../constants/actionConstants";

const initialState = {
  savedCard: [],
  balance: {},
  transactionHistory: [],
  paymentDone: false,
  fetchedSavedCard: false,
  fetchedtransactionHistory: false,
  cardRemoved: false
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PAYMENT_MADE_SUCCESS:
      return {
        ...state,
        balance: action.balance,
        paymentDone: true
      };
    case CONSTANTS.PAYMENT_MADE_FAIL:
      return {
        ...state,
        balance: {},
        paymentDone: false
      };
    case CONSTANTS.SAVED_CARD_SUCCESS:
      return {
        ...state,
        savedCard: action.savedCard,
        fetchedSavedCard: true
      };
    case CONSTANTS.SAVED_CARD_FAIL:
      return {
        ...state,
        savedCard: [],
        fetchedSavedCard: false
      };
    case CONSTANTS.TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistory: action.transactionHistory,
        fetchedtransactionHistory: true
      };
    case CONSTANTS.SAVED_CARD_FTRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        transactionHistory: [],
        fetchedtransactionHistory: false
      };
    case CONSTANTS.REMOVE_CARD_SUCCESS: {
      return {
        ...state,
        savedCard: [],
        cardRemoved: true
      };
    }
    case CONSTANTS.REMOVE_CARD_FAIL:
      return {
        ...state,
        cardRemoved: false
      };
    default:
      return state;
  }
};
export default paymentReducer;
