import * as CryptoJS from "crypto-js";
// import { EncryptionService } from './encryption.service';
import _ from "lodash";

export class LocalStorage {
  // private data: any;
  // private ls: any;
  // private key = "_PARTNER_PORTAL_";

  constructor() {
    this.data = "";
    this.ls = window.localStorage;
    this.key = "_PARTNER_PORTAL_";
    this.salt = "servicemarketenckey";

    if (this.ls) {
      try {
        this.ls.setItem("_SM_TEST_KEY_", "_SM_TEST_VALUE_");
        this.ls.removeItem("_SM_TEST_KEY_");
        this.initStorage();
      } catch (e) {
        this.ls = null;
      }
    } else {
      this.ls = null;
    }
  }

  encrypt(value) {
    return CryptoJS.AES.encrypt(value, this.salt).toString();
  }
  decrypt(value) {
    return CryptoJS.AES.decrypt(value, this.salt).toString(CryptoJS.enc.Utf8);
  }

  initStorage() {
    this.data = JSON.parse(this.ls.getItem(this.key) || "{}");
  }

  set(data) {
    for (var i in data) {
      this.data[i] = data[i];
    }
    if (this.ls) {
      this.ls.setItem(this.key, JSON.stringify(this.data));
    }
  }

  get(key, defaultVal = null) {
    return this.data[key] ? this.data[key] : defaultVal;
  }

  reset() {
    this.data = {};
    this.ls.removeItem(this.key);
  }

  buildCache(response) {}

  setBookingCache(response) {
    var self = this;
    // response = response.json();
    if (response.data) {
      self.set({ bookingConstants: response.data.constants });
    }
  }
  setConstantsCache(response, bookingReasons) {
    var self = this;
    // response = response.json();
    var dictionaryArr = [];
    var categories = [];
    var rejectionReasonsForBookingsArr = [];

    if (response.data) {
      for (var k = 0; k < response.data.length; k++) {
        var category = response.data[k].category;
        // eslint-disable-next-line
        bookingReasons.find(bookingReason => {
          if (bookingReason === category) {
            rejectionReasonsForBookingsArr.push(response.data[k]);
          }
        });
        for (var x = 0; x < response.data[k].dataValues.length; x++) {
          dictionaryArr[response.data[k].dataValues[x].id] =
            response.data[k].dataValues[x];
        }
        categories[response.data[k].id] = response.data[k];
      }
    }
    var dictArrEnc = this.encrypt(JSON.stringify(dictionaryArr));
    self.set({ constantDictionary: dictArrEnc });
    self.set({ categories: this.encrypt(JSON.stringify(categories)) });
    self.set({ rejectionReasonsForBookings: rejectionReasonsForBookingsArr });
  }

  setAdditionalServices(response) {
    var self = this;
    // response = response.json();

    if (response.data) {
      var subServices = response.data.subService;
      var res = {};
      for (var v in subServices) {
        res[subServices[v].id] = subServices[v].otherServices;
      }
      var encData = this.encrypt(JSON.stringify(res));
      self.set({ additionalServices: encData });
    }
  }

  setServices(response) {
    var self = this;
    // response = response.json();

    let services = [];
    let servicesByLabel = {};

    if (response.data) {
      for (var i in response["data"]["parentServices"]) {
        var s = response["data"]["parentServices"][i];
        services[s["id"]] = _.cloneDeep(s);
        servicesByLabel[s["label"]] = _.cloneDeep(s);
      }

      for (var ii in response["data"]["subServices"]) {
        var ss = response["data"]["subServices"][ii];
        services[ss["id"]] = _.cloneDeep(ss);
        servicesByLabel[ss["label"]] = _.cloneDeep(ss);
      }

      var serviceEn = this.encrypt(JSON.stringify(services));
      var serviceByLabelEn = this.encrypt(JSON.stringify(servicesByLabel));
      self.set({ services: serviceEn, servicesByLabel: serviceByLabelEn });
    }
  }

  setCurrencies(response) {
    var self = this;
    // response = response.json();

    let currencies = [];

    if (response.data) {
      for (var i in response["data"]["quoteUnits"]) {
        if (response["data"]["quoteUnits"][i].status === 1)
          currencies[i] = response["data"]["quoteUnits"][i];
      }
      self.set({ currencies: currencies });
    }
  }

  setCountryCityArea(response) {
    var self = this;
    let countryCityArea = {};
    let countryCityAreaForBooking = {};
    // response = response.json();
    for (var i in response["data"]) {
      countryCityArea[i] = response["data"][i];
      if (response["data"][i]["currencyDto"].code != null)
        countryCityAreaForBooking[response["data"][i].id] =
          response["data"][i]["currencyDto"].code;
    }
    self.set({ countryCityArea: countryCityArea });
    self.set({ countryCityAreaForBooking: countryCityAreaForBooking });
  }

  setQuoteAdditionalFields(response) {
    // var data = JSON.parse(response._body);
    this.set({
      quoteAdditionalFields: response.data.quotationAdditionalFieldDtos
    });
  }

  setCountryCityAreaForDelivered(response) {
    var self = this;
    let countryCityArea = {};
    let countryCityAreatemp = {};
    // response = response.json();
    for (var i in response["data"]) {
      countryCityAreatemp[response["data"][i].id] = response["data"][i];
      if (countryCityAreatemp[response["data"][i].id].utcOffset != null) {
        countryCityArea[response["data"][i].id] = response["data"][i];
      }
    }
    self.set({ countryCityAreaForDelivered: countryCityArea });
  }
}
