import axios from "axios";
import { loadState } from "../storage/localStorage";
import { ERRORS, APP_ROUTES } from "../constants/APP_CONSTANTS";

export function getAccessToken() {
  const accessToken = loadState().loginReducer
    ? loadState().loginReducer.user.access_token
    : "";
  return accessToken;
}

export const get = (url, headers, requireToken = true) => {
  return axios
    .get(url, { headers: makeHeaders(headers, requireToken) })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      catchAuthorizationException(error);
      return Promise.reject(error);
    });
};

export const post = (
  url,
  body,
  headers,
  requireToken = true,
  validateStatus = true
) => {
  let header = { headers: makeHeaders(headers, requireToken) };
  if (!validateStatus) {
    header["validateStatus"] = validateStatus;
  }
  return axios
    .post(url, body, header)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      catchAuthorizationException(error);
      return Promise.reject(error);
    });
};

export const put = (
  url,
  body,
  headers,
  requireToken = true,
  validateStatus = true
) => {
  let header = { headers: makeHeaders(headers, requireToken) };
  if (!validateStatus) {
    header["validateStatus"] = validateStatus;
  }
  return axios
    .put(url, body, header)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      catchAuthorizationException(error);
      return Promise.reject(error);
    });
};

export const deleteReq = (url, headers, requireToken = true) => {
  return axios
    .delete(url, { headers: makeHeaders(headers, requireToken) })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      catchAuthorizationException(error);
      return Promise.reject(error);
    });
};

export const patch = (url, body, headers, requireToken = true) => {
  return axios
    .patch(url, body, { headers: makeHeaders(headers, requireToken) })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      catchAuthorizationException(error);
      return Promise.reject(error);
    });
};

const makeHeaders = (headers, requireToken) => {
  if (requireToken) {
    const authHeader = makeAuthorizationHeaderArray(getAccessToken());
    return { ...authHeader, ...headers };
    //return headers; // Uncomment to raise 401;
  }
  return headers;
};

const makeAuthorizationHeaderArray = accessToken => {
  return {
    Authorization: `Bearer ${accessToken}`,
    accessToken: accessToken
  };
};

// const makeQueryParams = queryParamsArray => {
//     var str = [];
//     for (var p in queryParamsArray)
//         if (queryParamsArray.hasOwnProperty(p) && queryParamsArray[p] != null) {
//             str.push(encodeURIComponent(p) + "=" + encodeURIComponent(queryParamsArray[p]));
//         }
//     return str.join("&");
// };

const catchAuthorizationException = error => {
  if (
    error &&
    error.response &&
    error.response.status === ERRORS.CODES.UNAUTHORIZED_ACCESS
  ) {
    //this.ls.removeAllLocalStorageItems();
    localStorage.clear();
    window.location.href = APP_ROUTES.LOGIN;
  }
};
