import { getEnvBaseAPIURL } from "./common";
export const DEBUG = process.env.REACT_APP_ENV !== "production";

export const API = {
  BASE_URL: getEnvBaseAPIURL(process.env.REACT_APP_ENV),
  ENDPOINTS: {
    LOGIN: "oauth/token",
    RESET_PASSWORD: "v1.0/user/:email/reset-user-login-pp-password",
    UPDATE_PASSWORD: "v1.0/user/update-user-login-password",
    GET_COUNTS: "v2/booking/counters?numberOfHours=48",
    GET_CHANNEL_URL: "v1.0/sendbird/create-channel/:bookingEventId",
    GET_PARTNER_CHANNEL_URL:
      "v1.0/sendbird/create-channel/ops-partner/:bookingEventId",
    COMPLETE_REG: {
      VALIDATE: "v1.0/service-provider/validate-user-token/:token",
      SIGNUP: "v1.0/service-provider/signup-user/:token",
      OTP_VERIFY: "v1.0/service-provider/user-verification/:token",
      INVITE: "v1.0/service-provider/invite-user"
    },
    DASHBOARD: {
      TOTAL_BOOKING_COUNT:
        "v1.0/partner-dashboard/booking-count?from=:fromDate&to=:toDate",
      TOTAL_LEAD_COUNT:
        "v1.0/service-provider/dashboard/lead-count?from=:fromDate&to=:toDate&parentServiceId=:parentServiceId",
      TOTAL_BILL: "v1.0/service-provider/dashboard/billing-amount",
      MONTHLY_LEAD_COUNT:
        "v1.0/service-provider/dashboard/get-monthly-leads-count",
      FUTURE_EVENT_LIST: "v1.0/service-provider/dashboard/get-future-events",
      BOOKING_REVENUE:
        "v1.0/service-provider/dashboard/get-bookings-revenue?from=:fromDate&to=:toDate",
      GET_RATINGS:
        "v1.0/service-provider/dashboard/ratings?from=:fromDate&to=:toDate",
      GET_LEAD_TYPES: "v1.0/service-provider/services",
      COMPLAINTS_COUNT:
        "v1.0/partner-dashboard/complaints-count?from=:fromDate&to=:toDate",
      TECHNICIAN_TARGET_COUNT:
        "v1.0/partner-dashboard/technicians-data?from=:fromDate&to=:toDate"
    },
    BOOKINGS_AND_EVENTS: {
      GRIDS: {
        NEW: "v2/booking/list/BOOKING_STATUS_NEW",
        BANKED: "v2/booking/list/BOOKING_ALLOCATION_STATUS_BANKED",
        SOFT: "v2/booking/list/BOOKING_STATUS_UNVERIFIED_PAYMENT_METHOD",
        ACCEPTED: "v2/booking/list/BOOKING_STATUS_CONFIRMED",
        REJECTED: "v2/booking/list/BOOKING_STATUS_REJECTED",
        ACCEPTED_AND_UNASSIGNED:
          "v2/booking/list/BOOKING_STATUS_ACCEPTED_WITHOUT_MAIDS",
        ON_HOLD: "v2/booking/list/BOOKING_STATUS_ON_HOLD",
        FREEZED: "v2/booking/list/BOOKING_STATUS_FREEZED",
        CHANGE_REQUEST: "v2/booking/list/BOOKING_STATUS_CHANGE_REQUESTED",
        CHANGE_REQUEST_v1: "v1.0/read/partner/change-request",
        CANCELLED: "v2/booking/list/BOOKING_STATUS_CANCELLED",
        ALL: "v2/booking/list/BOOKING_STATUS_ALL",
        PLANNED:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_PLANNED,BOOKING_EVENT_STATUS_CHANGE_REQUESTED",
        IN_PROGRESS: "v1.0/booking-event/list/BOOKING_EVENT_STATUS_IN_PROGRESS",
        PENDING_IN_PROGRESS:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_PENDING_IN_PROGRESS",
        PENDING_CONFIRMATION:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_PENDING_CONFIRMATION",
        DELIVERED_AND_PAID:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_DELIVERED_AND_PAID",
        DELIVERED_AND_UNPAID:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_DELIVERED_AND_UNPAID",
        CANCELLED_AND_PAID:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_CANCELLED_AND_PAID",
        CANCELLED_EVENTS:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_CANCELLED",
        EVENT_ALL: "v1.0/booking-event/list/BOOKING_EVENT_STATUS_ALL",
        BOOKING_EVENT_STATUS_QUOTE:
          "v1.0/booking-event/list/BOOKING_EVENT_STATUS_QUOTE",
        BOOKING_EVENT_QUOTE_STATUS_OFFERED:
          "v1.0/booking-event/list/BOOKING_EVENT_QUOTE_STATUS_OFFERED"
      },
      DETAILS_PAGES: {
        BOOKINGS_v1:
          "v1.0/read/partner/getBookingDetails?bookingId={bookingId}&includeChangeRequests=1",
        BOOKINGS: "v2/booking/:bookingId/detail",
        EVENTS_v1:
          "v1.0/read/partner/getBookingEventDetails?bookingEventId={bookingEventId}&includeChangeRequests=1",
        EVENTS: "v2/booking-event/{bookingEventId}/detail",
        EVENTS_OSP:
          "v1.0/read/partner/calendar/getBookingEventDetails?bookingEventId={bookingEventId}&originalServiceProviderId={originalServiceProviderId}&workerId={workerId}&serviceCode={serviceCode}"
      },
      UPDATE: {
        BOOKINGS: "v1.0/booking",
        EVENTS: "v1.0/bookingEvent/:bookingEventId"
      },
      BOOKING_CONFIRMATION: "v1.0/booking/confirmation",
      ASSIGN_WORKER: "v1.0/booking/assign-workers",

      BOOKING_ASSIGN_WORKER: "v1.0/booking/assign-workers",
      BOOKING_DELETE_WORKER: "v1.0/booking/delete-workers",
      EVENT_ASSIGN_WORKER: "v1.0/booking-event/assign-workers",
      EVENT_DELETE_WORKER: "v1.0/booking-event/delete-workers",

      CHANGE_WORKER_BOOKING: "v1.0/booking/change-workers",
      CHANGE_WORKER: "v1.0/change-workers",
      ALLOCATED_PARTNERS: "v1.0/service-provider/:bookingId/allocatedPartners",
      HISTORY_LOGS: {
        BOOKING: "v2/booking/activityLog/:bookingId",
        EVENT: "v2/booking-event/activityLog/:bookingEventId"
      },
      CHANGE_REQUESTS: {
        LIST_BY_BOOKING: "v2/booking-change-request/booking-detail/:bookingId",
        LIST_BY_EVENT:
          "v2/booking-change-request/booking-event-detail/:bookingEventId",
        ACCEPT: "v2/booking-change-request/accept",
        REJECT: "v2/booking-change-request/reject",
        ACCEPT_OR_REJECT_V1: "v1.0/booking-change-request/",
        CHANGE_REQUEST_WORKER: "v1.0/service-provider/changeRequestWorker",
        CREATE: {
          BOOKING: "v2/booking-change-request",
          EVENT: "v2/booking-change-request"
        }
      },
      CANCEL: {
        BOOKINGS: "v1.0/booking/status",
        EVENTS: "v1.0/bookingEvent/:bookingEventId",
        CHARGE_EVENT: "v1.0/penalty-charges/booking-event/"
      },
      MARK_AS_DELIVERED_AND_PAID: "v1.0/booking-event/:bookingEventId",
      HOME_CLEANING_MARKS_AS_DELIVERED_AND_PAID: "v2/bookingEvent/status",
      SET_END_DATE: "v1.0/booking",
      ACTIVATE_SOFT_BOOKING: "v2/booking/:bookingId/complete-booking-request",
      FEEDBACK_REASONS: "v1/feedback/reasons",
      SERVICE_AREAS: "v1/service-location/:serviceCode",
      SERVICE_PROVIDERS:
        "v1.0/service-provider/service/:serviceCode/city/:city",
      PRICE_PLAN: "v2/pricePlan/:serviceCode/:cityCode",
      CREATE_BOOKING: "v2/booking",
      GET_PARTNERS_FOR_SERVICE:
        "v1.0/service-provider/list/:cityCode/:serviceCode",
      VALIDATE_OR_REGISTER_CUSTOMER: "v2/customer/validate-and-register",
      REALLOCATE_PARTNER:
        "v2/booking/change-provider/:bookingId/:serviceProviderId",
      APPLY_COUPON:
        "v1.0/voucher/redeem/:couponCode/booking-event/:bookingEventId",
      PRICE_FOR_SERVICE: "v1.0/pricer/price-for-service/:bookingEventId",
      QUOTE_STATUS_UPDATE:
        "v1.0/booking-event/:bookingEventId/quote-status-update"
    },
    CONSTANTS: {
      CONSTANT: "v1/constants",
      COUNTRY_CITY_AREA: "v1.0/read/country-city-area",
      BOOKABLE_SERVICE: "v2/read/bookable-services",
      DATA_DICTIONARY_VALUES: "v1.0/read/data-dictionary-values",
      SERVICES: "v1.0/read/services",
      QUOTE_UNIT: "v1.0/quote/units",
      ADDITIONAL_FIELDS: "v1.0/quote/additional-fields",
      GET_AVAILABLE_BALANCE: "v1.0/read/partner/get-available-balance"
    },
    CLEANER: {
      REGISTER_CLEANER: "v1.0/service-provider/worker",
      CLEANER_LIST: "v1.0/service-provider/workers",
      CLEANER_DETAILS: "v1.0/service-provider/workerDetail",
      GET_MAIDS: "v1.0/service-provider/listOfWorker",
      VERIFY_PHONE_NUMBER: "v1.0/service-provider/verify-worker-phone-number",
      VOCATIONAL_DATA: "v1.0/service-provider-worker/worker-vocational-data",
      GET_CALENDAR_DATA: "v1.0/service-provider-worker/booking-slot-details",
      LIST_WORKER_AREAS: "v1.0/service-provider-worker/worker-areas"
    },
    TECHNICIAN: {
      REGISTER: "v1.0/service-provider/worker",
      LIST: "v1.0/service-provider/workers",
      SERVICE_PROVIDER_LIST: "v1.0/service-provider/all",
      DETAILS: "v1.0/service-provider/workerDetail",
      GET_MAIDS: "v1.0/service-provider/listOfWorker",
      VERIFY_PHONE_NUMBER: "v1.0/service-provider/verify-worker-phone-number",
      LIST_OF_WORKERS: "v1.0/service-provider-worker/workers"
    },
    USER: {
      INVITE: "v1.0/service-provider/invite-user",
      LIST: "v1.0/service-provider/all-users-and-invites",
      LIST_INVITES:
        "v1.0/service-provider/invited-users?providerId={providerId}",
      UPDATE_USER: "v1.0/user/updateUser.json",
      /*DETAILS: "v1.0/service-provider/workerDetail",
      GET_MAIDS: "v1.0/service-provider/listOfWorker"*/
      PROFILE: "v1.0/user/profile"
    },
    PROMOTION: {
      GET_LEADS: "v1.0/read/partner/getPromotionalLeads",
      LEAD_DETAILS: "v1.0/read/partner/getPromotionalLeadDetails"
    },
    PROFILE: {
      MY_PROFILE: "v1.0/read/partner/profile"
    },
    REPORTS: {
      GET_BILLING: "v1.0/read/partner/billing",
      GET_AVAILABLE_BALANCE: "v1.0/read/partner/get-available-balance"
    },
    PAYMENT: {
      GET_SAVED_CREDIT_CARD: "v2/creditCards",
      CREDIT_CARD_REGISTRATION: "v2/creditCards/registration",
      CREDIT_CARD_VERIFICATION: "v2/creditCards/verify/:processID",
      REMOVE_CREDIT_CARD: "v2/creditCards/remove/:cardID",
      MAKE_PAYMENT: "v2/creditCards/payment/provider",
      TRANSACTION_HISTORY: "v1/transaction/history"
    },
    QUOTES: {
      REQUEST_QUOTES: "v1.0/leads?status=:status",
      REQUEST_QUOTES_DETAILS_FOR_OTHER_SERVICES:
        "v1.0/{quotesType}/detail?requestId={:requestId}",
      REQUEST_QUOTES_DETAILS:
        "v1.0/read/partner/request/{quotesType}?requestId={:requestId}",
      REQUEST_QUOTES_UPDATE: "/v1.0/quote/updateMultipleQuotes.json",
      HOUSE_HOLD_REQUEST_QUOTES_UPDATE:
        "/v1.0/quote/updateMultipleHHquote.json",
      FILE_UPLOAD: "v1.0/quote/file-upload"
    },
    CUSTOMER: "v1.0/custom-services/all",
    VERSION_CHECK: "v1.0/mobile/{currentVersion}/PARTNER_PORTAL",
    CATALOG: {
      SKUS: "v1.0/product-catalog/{serviceCode}/{cityCode}"
    },
    ZENDESK: {
      LIST: "v1.0/complaint/ticket",
      TICKET_DETAILS: "v1.0/complaint/ticket/:ticketId",
      UPDATE: "v1.0/complaint/ticket",
      SUPPORT_TICKET_LIST: "v1.0/support-desk/tickets",
      SUPPORT_TICKET_TICKET_DETAILS: "v1.0/support-desk/ticket/:ticketId",
      REPLY_SUPPORT_TICKET: "v1.0/support-desk/comment/ticket/:ticketId"
    },
    SERVICE_PROVIDERS: {
      DROP_DOWN_LIST: "v1.0/service-provider/all",
      DETAILS: "v1.0/service-provider/:providerId",
      WORKER_DETAILS: "v1.0/service-provider/worker-details?workerId="
    },
    RATING: {
      LIST: "v1.0/partner-dashboard/ratings-detail"
    }
  }
};
