import CONSTANTS from "../constants/actionConstants";

const initialState = {
  error: "",
  profile: {
    addressDetails: [{}],
    contactInformationDetails: [{}, {}]
  },
  profileSuccess: false
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    // case CONSTANTS.REQUEST_INITIATE:
    //     return {
    //         ...state,
    //         error: '',
    //         user: {},
    //     };

    case CONSTANTS.PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.profile,
        profileSuccess: true
      };
    }

    case CONSTANTS.PROFILE_FAIL:
      return {
        ...state,
        profile: {},
        profileSuccess: false
      };

    default:
      return state;
  }
};
export default profileReducer;
