//import Http from "../../../shared/http";
import { API } from "../../constants/API_URL_CONSTANTS";
import { put, get, deleteReq } from "../../utils/http";

class NotificationService {
  registerDevice(deviceUUID) {
    let url =
      API.BASE_URL +
      `v1.0/notification/register/generic/device/${deviceUUID}?appName=PARTNER`;
    return put(url).then(res => {
      return res;
    });
  }
  deleteDevice(deviceUUID) {
    let url =
      API.BASE_URL +
      `v1.0/notification/register/generic/device/${deviceUUID}?appName=PARTNER`;
    return deleteReq(url).then(res => {
      return res;
    });
  }
  getNotificationList(
    status,
    pageSize = 10,
    pageNumber = 0,
    isUnreadOnly = false,
    isDedicatedOnly = false
  ) {
    let url = API.BASE_URL + "v1.0/notification-center";
    url += status !== "all" ? `/${status}` : "";
    url += `?size=${pageSize}&page=${pageNumber}`;
    if (isUnreadOnly) {
      url += `&is_read=${!isUnreadOnly}`;
    }
    if (isDedicatedOnly) {
      url += `&is_dedicated=${isDedicatedOnly}`;
    }
    return get(url)
      .then(res => {
        if (res && res.data) {
          return res.data;
        }
      })
      .catch(function(error) {
        return error;
      });
  }
  markUsRead = (notificationId, markUsRead = true) => {
    let urlEndPoint = markUsRead ? "mark-as-read" : "mark-as-unread";
    let url =
      API.BASE_URL +
      `v1.0/notification-center/${notificationId}/${urlEndPoint}`;
    return put(url)
      .then(res => {
        return res;
      })
      .catch(function(error) {
        return error;
      });
  };
  getNotificationCount = () => {
    let url = API.BASE_URL + "v1.0/notification-center/count";
    return get(url)
      .then(res => {
        return res.data;
      })
      .catch(function(error) {
        return error;
      });
  };
}
export default NotificationService;
