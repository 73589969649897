import CONSTANTS from "../constants/actionConstants";

const initialState = {
  fetchError: "",
  billedData: [],
  allLocalSavedBills: [],
  calculatedBalance: {
    availableBalance: "",
    totalBill: ""
  },
  calculatedBalanceSuccess: false,
  fetchSuccess: false
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.MY_BILLING_INIT:
      return {
        ...state,
        fetchError: "",
        billedData: [],
        fetchSuccess: false
      };

    case CONSTANTS.MY_BILLING_SUCCESS: {
      return {
        ...state,
        fetchError: "",
        billedData: action.billedData,
        allLocalSavedBills: action.billedData,
        fetchSuccess: true
      };
    }

    case CONSTANTS.MY_BILLING_FAIL:
      return {
        ...state,
        fetchError: action.error,
        billedData: [],
        fetchSuccess: false
      };

    case CONSTANTS.AVAILABLE_BALANCE_SUCCESS: {
      return {
        ...state,
        fetchError: "",
        calculatedBalance: action.calculatedBalance,
        calculatedBalanceSuccess: true
      };
    }

    case CONSTANTS.AVAILABLE_BALANCE_FAIL:
      return {
        ...state,
        fetchError: action.error,
        calculatedBalance: {},
        calculatedBalanceSuccess: false
      };

    case CONSTANTS.SEARCH_BILL_FROM_FETCHED_DATA: {
      const searchedRecords = [];
      if (action.searchKeyword === null) {
        return {
          ...state,
          fetchError: "",
          billedData: state.allLocalSavedBills,
          fetchSuccess: true
        };
      } else {
        state.allLocalSavedBills.forEach(item =>
          Object.keys(item).map(key => {
            if (item[key] && typeof item[key] === "string") {
              if (
                item[key]
                  .toLowerCase()
                  .search(action.searchKeyword.toLowerCase()) !== -1
              ) {
                searchedRecords.push(item);
              }
            }
            return searchedRecords;
          })
        );
        return {
          ...state,
          fetchError: "",
          billedData: searchedRecords,
          fetchSuccess: true
        };
      }
    }
    default:
      return state;
  }
};
export default reportReducer;
