import CONSTANTS from "../constants/actionConstants";

const initialState = {
  eventDetails: {},
  changeRequestDetails: {},
  changeRequestDetailsSuccess: {},
  eventDetailsSuccess: false
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        eventDetails: action.eventDetails,
        eventDetailsSuccess: true
      };
    }
    case CONSTANTS.EVENT_DETAILS_FAIL:
    case CONSTANTS.EVENT_DETAILS_RESET:
      return {
        ...state,
        eventDetails: {},
        changeRequestDetails:{},
        changeRequestDetailsSuccess: false,
        eventDetailsSuccess: false
      };
    case CONSTANTS.EVENT_CHANGE_REQUEST_SUCCESS: {
      return {
        ...state,
        changeRequestDetails: action.changeRequestDetails,
        changeRequestDetailsSuccess: true
      };
    }
    case CONSTANTS.EVENT_CHANGE_REQUEST_FAIL:
      return {
        ...state,
        changeRequestDetails: {},
        changeRequestDetailsSuccess: false
      };
    default:
      return state;
  }
};
export default eventReducer;
