import CONSTANTS from "../constants/actionConstants";
import { saveState } from "../storage/localStorage";

const initialState = {
  error: "",
  user: {
    profile: {},
  },
  isLoggedIn: false,
  errorInLogin: false,
  showLoader: false,
  requestCompleted: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.REQUEST_INITIATE:
      return {
        ...state,
        error: "",
        user: {
          profile: {},
        },
        isLoggedIn: false,
        errorInLogin: false,
        showLoader: true,
        requestCompleted: false
      };

    case CONSTANTS.LOGIN_SUCCESS: {
      saveState({
        loginReducer: {
          user: action.user
        }
      });
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
        error: "",
        errorInLogin: false,
        showLoader: false,
        requestCompleted: true
      };
    }

    case CONSTANTS.LOGIN_FAIL:
      return {
        ...state,
        error: 401,
        user: {
          profile: {},
        },
        isLoggedIn: false,
        errorInLogin: true,
        showLoader: false,
        requestCompleted: true
      };
    case CONSTANTS.LOG_OUT:
      return {
        ...initialState
      }
    default:
      return state;
  }
};
export default loginReducer;
