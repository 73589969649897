import SecureLS from "secure-ls";

const secureLS = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "SJH3pCQOk2D21*^Kt6M!PNKX4EcZC#hrBWNtWsIgkjrSbPWIF3"
});

export const setConstantsCacheWithoutModule = (constactName, constantData) => {
  return secureLS.set(constactName, JSON.stringify(constantData));
};

export const getConstantsCache = constactName => {
  const constData = secureLS.get(constactName);
  if (constData) {
    return JSON.parse(constData);
  }
  return {};
};

export const loadState = () => {
  const localData = secureLS.get("bits");
  if (localData) {
    return JSON.parse(localData);
  }
  return {};
};

export const saveState = state => {
  return secureLS.set("bits", JSON.stringify(state));
};
