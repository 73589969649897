import CONSTANTS from "../constants/actionConstants";

const initialState = {
  fetchError: "",
  promotionalLead: [],
  allLocalSavedLeads: [],
  leadDetails: {},
  leadDetailsSuccess: false,
  fetchSuccess: false
};

const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PROMOTION_LEAD_INIT:
      return {
        ...state,
        fetchError: "",
        promotionalLead: [],
        fetchSuccess: false
      };

    case CONSTANTS.GET_PROMOTION_LEAD_SUCCESSS: {
      return {
        ...state,
        fetchError: "",
        promotionalLead: action.promotionalLeads,
        allLocalSavedLeads: action.promotionalLeads,
        fetchSuccess: true
      };
    }

    case CONSTANTS.GET_PROMOTION_LEAD_FAIL:
      return {
        ...state,
        fetchError: action.error,
        promotionalLead: [],
        fetchSuccess: false
      };

    case CONSTANTS.SEARCH_LEAD_FROM_FETCHED_DATA: {
      const searchedRecords = [];
      if (action.searchKeyword === null) {
        return {
          ...state,
          fetchError: "",
          promotionalLead: state.allLocalSavedLeads,
          fetchSuccess: true
        };
      } else {
        state.allLocalSavedLeads.forEach(item =>
          Object.keys(item).map(key => {
            if (item[key] && typeof item[key] === "string") {
              if (
                item[key]
                  .toLowerCase()
                  .search(action.searchKeyword.toLowerCase()) !== -1
              ) {
                searchedRecords.push(item);
              }
            }
            return searchedRecords;
          })
        );
        return {
          ...state,
          fetchError: "",
          promotionalLead: searchedRecords,
          fetchSuccess: true
        };
      }
    }

    case CONSTANTS.LEAD_DETAILS_SUCCESS: {
      return {
        ...state,
        leadDetails: action.leadDetails,
        leadDetailsSuccess: true
      };
    }

    case CONSTANTS.LEAD_DETAILS_FAIL:
      return {
        ...state,
        leadDetails: {},
        leadDetailsSuccess: false
      };
    default:
      return state;
  }
};
export default promotionReducer;
